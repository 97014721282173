
import { useParams } from 'react-router';

import React, { useState, useEffect } from 'react';


function SuggestionsTable(props) {

  
  let { surveyId } = useParams();

  const [suggestions, setSuggestions] = useState([]);

  useEffect(() => {
    fetch(`/api/survey/${surveyId}/suggestions`)
      .then(response => response.json())
      .then(suggestions => setSuggestions(suggestions));
  },[]);

  return (
    <div>
      <h1>Suggestions</h1>

      <table className="table">
        <thead>
          <tr>
            <th>User</th>
            <th>Suggestion</th>
            <th>Parent</th>
          </tr>
        </thead>
        <tbody>
          {suggestions.map( s =>
            <tr>
              <td>
                {s.user}
              </td>
              <td>
                {s.response}
              </td>
              <td>
                {s.parent.includes('Suggestion_') && suggestions.find(p => p.questionText == s.parent)?.response + ' (also a suggestion)' || s.parent}
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )
}

export default SuggestionsTable;