import React, { useState, useEffect, useRef } from 'react';
import { FormGroup, FormText, Input, Label, Modal } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import { faAt, faVideo } from '@fortawesome/free-solid-svg-icons';
import SelectSearch, { fuzzySearch } from 'react-select-search';

export default function ChangeModeModal(props) {
  
  const [room, setRoom] = useState(props.room);

  const save = (e) => {
    props.saveAndClose(room);
  }
  const close = (e) => {
    props.saveAndClose();
  }

  const fullContents = <>
  <div class="modal-header">
    <h5 class="modal-title">Change settings for this room</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={close}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body"> 
    <FormGroup>
      <Label for="youTubeInput">
        Tree/Document
      </Label>
      <br/>
      <SelectSearch
        search
        placeholder="Tree"
        filterOptions={fuzzySearch}
        options={props.treeList}
        value={room.survey}
        onChange={(survey) => setRoom(prev => ({...prev, survey, viewRoot: null}))}
      />
      <br/>
    </FormGroup>
    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          checked={room.readOnlyMode}
          onChange={e => {let readOnlyMode = e.target.value; setRoom(prev => ({...prev, readOnlyMode}))}}
          id="readOnlyCheck"
          aria-describedby="readOnlyCheckHelp"
        />
        <label class="form-check-label" for="readOnlyCheck">
          Read-only mode
        </label>
        <FormText id="readOnlyCheckHelp">
          Prevents non-mods from adding items, except suggestions.
        </FormText>
      </div>
    </div>
    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          checked={room.enableTwisties}
          onChange={e => {let enableTwisties = e.target.value; setRoom(prev => ({...prev, enableTwisties}))}}
          id="enableTwistiesCheck"
          aria-describedby="enableTwistiesCheckHelp"
        />
        <label class="form-check-label" for="enableTwistiesCheck">
          Enable collapsible navigation
        </label>
        <FormText id="enableTwistiesCheckHelp">
          Changes the navigation of the tree to 'twisties' which can be expanded.
        </FormText>
      </div>
    </div>
    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          checked={room.enableTranslations}
          onChange={e => {let enableTranslations = e.target.value; setRoom(prev => ({...prev, enableTranslations}))}}
          id="translationsCheck"
          aria-describedby="translationsCheckHelp"
        />
        <label class="form-check-label" for="translationsCheck">
          Enable translations
        </label>
        <FormText id="translationsCheckkHelp">
          Allows users to change the language in the room and view translations.
        </FormText>
      </div>
    </div>
    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          checked={room.enableSearch}
          onChange={e => {let enableSearch = e.target.value; setRoom(prev => ({...prev, enableSearch}))}}
          id="searchCheck"
          aria-describedby="searchCheckHelp"
        />
        <label class="form-check-label" for="searchCheck">
          Enable search
        </label>
        <FormText id="searchCheckHelp">
          Display the search box for users to find items in the tree
        </FormText>
      </div>
    </div>
    <div class="form-group">
      <div class="form-check">
        <input
          class="form-check-input"
          type="checkbox"
          checked={room.disableRankingMode}
          onChange={e => {let disableRankingMode = e.target.value; setRoom(prev => ({...prev, disableRankingMode}))}}
          id="disableRankingModeCheck"
          aria-describedby="disableRankingModeCheckHelp"
        />
        <label class="form-check-label" for="disableRankingModeCheck">
          Disable ranking mode
        </label>
        <FormText id="disableRankingModeCheckHelp">
          Removes the ability for mods to switch the room to ranking mode
        </FormText>
      </div>
    </div>
    <div className="form-group">
      
      <label for="treeModeSelect">Tree mode</label>
      <select
        id="treeModeSelect"
        className="form-control"
        aria-label="Default select example"
        value={room.treeMode}
        onChange={e => {let treeMode = e.target.value; setRoom(prev => ({...prev, treeMode}))}}
        aria-described-by="treeModeSelectHelp"
      >
        <option value="show">Show for all</option>
        <option value="hide">Hide for all</option>
        <option value="user">User can show/hide</option>
        <option value="treeOnly">Tree Only (no call/video)</option>
      </select>
      <FormText id="treeModeSelectHelp">
        Sets the mode for the tree. Change this on the room page for it to be pushed to all clients immediately.
      </FormText>
    </div>
    <div className="form-group">
      
      <label for="statusSelect">Room mode</label>
      <select
        id="statusSelect"
        className="form-control"
        aria-label="Default select example"
        value={room.status}
        onChange={e => {let status = e.target.value; setRoom(prev => ({...prev, status}))}}
        aria-described-by="statusSelectHelp"
      >
        <option value="vanilla">Vanilla</option>
        <option value="issue">Issue mode</option>
        <option value="solution">Solution mode</option>
        <option value="suggestion">Suggestion Mode</option>
        <option value="hub">Hub mode</option>
      </select>
      <FormText id="statusSelectHelp">
        Sets the mode for the room. Don't set to Hub mode unless you know the implications
      </FormText>
    </div>
    <FormGroup>
      <Label for="vimeoInput">
        Vimeo URL
      </Label>
      <Input
        type="text"
        value={room.vimeo}
        onChange={e => {let vimeo = e.target.value || null; setRoom(prev => ({...prev, vimeo}))}}
        id="vimeoInput"
        aria-describedby="vimeoInputHelp"
      />
      <FormText id="vimeoInputHelp">
        Adds a Vimeo player instead of a Twilio call
      </FormText>
    </FormGroup>
    <FormGroup>
      <Label for="youTubeInput">
        YouTube Embed ID
      </Label>
      <Input
        type="text"
        value={room.youTube}
        onChange={e => {let youTube = e.target.value || null; setRoom(prev => ({...prev, youTube}))}}
        id="youTubeInput"
        aria-describedby="youTubeInputHelp"
      />
      <FormText id="youTubeInputHelp">
        Adds a YouTube player. Note that Vimeo takes priority if also set.
      </FormText>
    </FormGroup>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      data-dismiss="modal"
      onClick={save}
      disabled={false}
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      onClick={close}
    >
      Cancel
    </button>
  </div>
</>;

  return (
    <Modal isOpen={true}>{fullContents}</Modal>
  )
}