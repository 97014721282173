import React, { useState, useEffect } from 'react';
import { faPaperPlane, faThumbsUp as faSolidThumbsUp, faTrashAlt }
  from "@fortawesome/free-solid-svg-icons";
  import { faThumbsUp as faRegularThumbsUp }
    from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactMarkdown from 'react-markdown';
import TreeLink from '../markdown/TreeLink';

function CommentViewer(props) {

  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState("");
  const handleLinkToQuestion = props.handleLinkToQuestion;

  useEffect(() => {

    setComments(props.comments);
    
  },[props.comments]);

  const handleInputChange = (e) => {
    e.preventDefault();
    setNewComment(e.target.value);
  }

  const handleNewItem = (e) => {
    e.preventDefault();
    props.hubConnection && props.hubConnection.
    invoke('newComment', props.docId, {
      location: props.location,
      content: newComment
    });
    setNewComment("")
  }

  const handleLike = (e) => {
    e.preventDefault();
    props.hubConnection && props.hubConnection.
    invoke('likeComment', e.currentTarget.dataset.comment, e.currentTarget.dataset.like)
  }

  const handleDelete = (e) => {
    e.preventDefault();
    props.hubConnection && props.hubConnection.
    invoke('deleteComment', props.roomId, e.currentTarget.dataset.comment)
  }

  return (
    <div className={"comment-viewer "}>
      {props.comments.length?
      <p>{props.comments.length} comment{props.comments.length != 1? 's' : null} on this item</p>
      :null}
      {props.comments.map(c => {
        let thisTranslation = (!['[','*','_'].some(char => c.content.includes(char)) && props.language && props.language != 'en' && c.translations?.find(tr => tr.language == props.language)) || null;
        return <div className="comment">
          {!!props.thisUser && <span className="float-right">
          {c.likes?.length? <span className="mr-1">{c.likes?.length}</span> : null }
            <span onClick={handleLike} data-comment={c.id} data-like={c.likes?.includes(props.thisUser.username)? "unlike": "like"}>
              <FontAwesomeIcon icon={c.likes?.includes(props.thisUser.username)? faSolidThumbsUp : faRegularThumbsUp} />
            </span>
            {c.author == props.thisUser.username || props.thisUser.rank == "mod"?
            <span data-tip="Delete this comment" onClick={handleDelete} data-comment={c.id} className="ml-2">
              <FontAwesomeIcon icon={ faTrashAlt } />
            </span>
            : null}
          </span>}
          <p className="comment-author">{c.author}</p>
          <p
            className="comment-content"
            data-tip={thisTranslation? `Original: ${c.content}`:null}
          >{
            c.displayAsMarkdown && ['[','*','_'].some(char => c.content.includes(char))?
            <ReactMarkdown
              allowedElements={['a', 'em', 'strong']}
              components={{a: ({node, ...props}) => <TreeLink handleLinkToQuestion={handleLinkToQuestion} {...props}/>}}
              linkTarget="_blank"
              unwrapDisallowed={true}
            >
              {c.content}
            </ReactMarkdown>
          :
            (thisTranslation?.translatedText || c.content)
          }</p>
        </div>}
      )}
      {props.canAddComments && <form onSubmit={handleNewItem}>
        <div className="form-group">
          <div className="input-group mt-2">
            <input
              autoFocus
              value={newComment}
              onChange={handleInputChange}
              type="text"
              className="form-control"
              placeholder="Add a comment"/>
            <div className="input-group-append">
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </div>
          </div>
        </div>
      </form>}
      
    </div>
  )
}

export default CommentViewer; 