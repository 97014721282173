import React, { useState, useEffect, useRef } from 'react';

function MatchResultItem(props) {

  const [pictureError, setPictureError] = useState(false);

  const onCallClick = (e) => {
    console.log(props.match.pairId);
    props.openCallWindow(props.match);
  }


  return (
    <div className="match-result-item clearfix">
      <img
        className="avatar"
        src={(props.match.profilePicture && !pictureError && !props.match.profilePicture.includes("avatar_imagesuserdefault")) ? props.match.profilePicture : process.env.PUBLIC_URL + '/guest.png'}
      />
      <span className="match-result-item-name">{props.match.user} 
        {/* <span className="match-result-item-score">({props.match.score})</span> */}
        {props.match.currentCall && 
          <span> wants you to join a call</span>
        }
      </span>
      <span className="match-result-item-reason">{props.match.reason}</span>
      <span className="match-result-item-call" onClick={onCallClick}>Call</span>
    </div>
  )



}

export default MatchResultItem