import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState
} from '@microsoft/signalr';
import styles from './MatcherRoom.module.css';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faQuestionCircle, faUserFriends, faUsers, faVideo } from '@fortawesome/free-solid-svg-icons';
import ProfileEditModal from './ProfileEditModal';
import ExternalCallInfoModal from './ExternalCallInfoModal';
import ExternalCallJoinModal from './ExternalCallJoinModal';
import ProfileBar from './ProfileBar';
import CallRoomItem from './CallRoomItem';
import RecorderLinkButtonAndModal from '../recorder/RecorderLinkButtonAndModal';
let cx = classNames.bind(styles);

function MatcherRoom(props) {

  const [matches, setMatches] = useState(null);
  const [callRoom, setCallRoom] = useState(null);
  const [myProfile, setMyProfile] = useState(null);
  const [calls, setCalls] = useState([]);
  const [hub, setHub] = useState(null);
  const [showTree, setShowTree] = useState(null);
  const [showExternalModal, setShowExternalModal] = useState(null);
  const lastHubState = useRef(null);
  const callEndpoint = "https://interactive.andeye.com:9443";

  let { surveyId } = useParams();

  useEffect(() => {
    populateMyProfile()
    if (surveyId) {
      populateMatchData(surveyId);
      populateInviteData(surveyId);
    }
    const hubConnection = new HubConnectionBuilder()
    .withUrl("/controlHub")
    .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
    .configureLogging(LogLevel.Information)
    .build();
    hubConnection
    .start()
    .then(() => {
      setHub(hubConnection);
    });
    
  }, [surveyId]);

  useEffect(() => {
    if (hub?.state === HubConnectionState.Connected && hub.state != lastHubState.current) {
      hub.invoke("SubscribeRoom", surveyId);
      hub.off("callRoomJoin");
      hub.on("callRoomJoin", (callRoom) => {
        setCallRoom(callRoom);
        hub.invoke("JoinCallRoom", callRoom.id)
      });
      // hub.off("callRoomInvite");
      // hub.on("callRoomInvite", (invite) => {
      //   console.log(invite);
      //   console.log(invites);
      //   setInvites(prev => [...prev.filter(cr => cr.id != invite.id), invite]);
      // });
      hub.off("callRoomUpdate");
      hub.on("callRoomUpdate", (updatedRoom) => {
        setCalls(prev => [...prev.filter(cr => cr.id != updatedRoom.id), updatedRoom])
        // setInvites(prev => prev.find(i => i.id == updatedRoom.id) || updatedRoom.invitees.includes(myProfile.username)? [...prev.filter(cr => cr.id != updatedRoom.id), updatedRoom] : prev);
        setCallRoom(prev => prev?.id == updatedRoom.id? (updatedRoom.isOpen? updatedRoom : null) : prev);
      });
      hub.off("profileUpdate");
      hub.on("profileUpdate", (newProfile) => {
        setMatches(prev =>
          prev.map(m =>
            (m.user == newProfile.username) ?
              {
                ...m,
                nickname:newProfile.nickname,
                summary: newProfile.summary,
                wantsToTalk: newProfile.wantsToTalk,
                profilePicture: newProfile.profilePicture
              }
            : m
          )
        )
      });
      lastHubState.current = hub.state;
    }
    
  },[hub, myProfile]);

  useEffect(() => {
    console.log(matches);
  }, [matches]);

  useEffect(() => {
    if (calls && myProfile) {
      setCallRoom(
        calls.find(c => c.isOpen && (c.currentParticipants?.includes(myProfile.username)))
      )
    }
  }, [calls, callRoom, myProfile])

  const populateMatchData = async (surveyId) => {
    fetch(`/api/survey/${surveyId}/matches`)
    .catch(e => {
      console.log(e);
    })
    .then(response => response.json())
    .then(data => setMatches(data));
    // Run this again in 60-120 seconds.
    setTimeout(() => populateMatchData(surveyId), (Math.random() + 1) * 60000);
  }

  const populateInviteData = async (surveyId) => {
    fetch(`/api/survey/${surveyId}/callroom`)
    .then(response => response.json())
    .then(data => setCalls(data));
  }

  const populateMyProfile = async () => {
    fetch(`/api/user`)
    .then(response => response.json())
    .then(data => setMyProfile(data))
    setTimeout(populateMyProfile, (Math.random() + 1) * 60000)
  }

  const createWithUser = (e) => {
    let newCallRoom = {
      parentRoom: surveyId,
      invitees: [e.currentTarget.dataset.username],
      isOpen: true,
      // If the creator is using external video, then set this up for this call
      isExternal: myProfile.useExternalVideo,
      externalLink: myProfile.useExternalVideo ? myProfile.externalCallLink : null
    }
    hub.invoke("CreateCallRoom", newCallRoom);
  }

  const joinCall = (e) => {
    setCallRoom(calls.find(i => i.id == e.currentTarget.dataset.call));
    hub.invoke("JoinCallRoom", e.currentTarget.dataset.call)
  }

  const leaveCall = (e) => {
    // setCallRoom(null);
    hub.invoke("LeaveCallRoom", e.currentTarget.dataset.call)
    if (!callRoom?.isExternal) {
      try {
        fetch(`https://interactive.andeye.com:9443/twilio/rooms/disconnect`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({'username': myProfile.username, 'room': e.currentTarget.dataset.call})
        })
        .catch(console.error)
      } catch (e) {
        console.log(e);
      }
    }
  }

  const inviteToCall = (e) => {
    if (callRoom) {
      hub.invoke("InviteToCallRoom", callRoom.id, e.currentTarget.dataset.username);
    }
  }

  const uninviteFromCall = (e) => {
    if (callRoom) {
      hub.invoke("UninviteFromCallRoom", callRoom.id, e.currentTarget.dataset.username);
    }
  }

  const handleShowTree = (e) => {
    setShowTree("614c7886dfdb08de2668ec81")
  }

  const handleHideTree = (e) => {
    setShowTree(null)
  }

  const handleCloseProfileEditModal = (profile) => {
    setShowExternalModal(false);
    if (profile) {
      setMyProfile(profile);
      fetch(`/api/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(profile)
      })
    }
  }

  const userProfileIfExist = (username, profiles) => {
    return profiles?.find(m => m.user == username)
  }

  const meetingInfo = (url) => {
    const meetingSystems = [
      {
        url: 'zoom.us',
        serviceName: 'Zoom',
        icon: faVideo
      },
      {
        url: 'teams.microsoft.com',
        serviceName: 'Teams',
        icon: faUserFriends
      }
    ]
    try {
      const parsedUrl = new URL(url);
      return meetingSystems.find(ms => parsedUrl?.hostname.match(`(^|\\.)${ms.url}$`));
    } catch (e) {
      return null;
    }
  }

  return (
    <>
      <div className={cx(styles.listContainer, {listContainerHalfSize: callRoom && !callRoom.isExternal})}>
        {showTree &&
          <>
            <iframe allow="camera;microphone;display-capture" className={styles.showTreeFrame} src={`/sharedtree/${showTree}?tree-only`}></iframe>
            <div className={styles.showTreeHeader}>
              <div className="container">
                <button
                  type="button"
                  className={cx("btn","btn-primary","btn-sm","mt-2",styles.hideTreeButton)}
                  onClick={handleHideTree}
                >
                  <FontAwesomeIcon icon={faUsers} /> See other participants
                </button>
              </div>
            </div>
          </>
        }
        <div className="container">
          <div className="row">
            <div className="col-6 col-md-8">
              <div className={styles.help}>
                <a 
                  className="btn btn-secondary btn-sm mr-2 mb-2"
                  target="_blank"
                  href="https://wcd.andeye.com/help">
                  <FontAwesomeIcon icon={faQuestionCircle}/> Help
                </a>
                {/* <button
                  type="button"
                  className="btn btn-primary btn-sm mr-2 mb-2"
                  onClick={handleShowTree}
                >
                  <FontAwesomeIcon icon={faFileSignature}/> Open the Call to Action
                </button> */}
                <button
                  type="button"
                  className="btn btn-info btn-sm mr-2 mb-2"
                  onClick={e => {setShowExternalModal(true)}}
                >
                  <FontAwesomeIcon icon={faQuestionCircle}/> Can't access andeye Video?
                </button>
                <RecorderLinkButtonAndModal/>
                {showExternalModal &&
                  <ExternalCallInfoModal
                    myProfile={myProfile}
                    onClose={handleCloseProfileEditModal}
                    meetingInfo={meetingInfo}
                  />
                }
              </div>
            </div>
            {myProfile &&
              <ProfileBar
                clickable={true}
                myProfile={myProfile}
                setMyProfile={setMyProfile}
              />

            }
          </div>
          
          {callRoom?.isOpen && 
            <>
              <p>Your current call:</p>
              <CallRoomItem
                callRoom={callRoom}
                myProfile={myProfile}
                isMyRoom={callRoom.creator == myProfile?.username}
                userProfileIfExist={(username) => userProfileIfExist(username, matches)}
                meetingInfo={meetingInfo}
                joinCall={joinCall}
                leaveCall={leaveCall}
                enableRealTimeButtons={(hub?.state === HubConnectionState.Connected)}
                isCurrentlyInCall={callRoom.isOpen && callRoom.currentParticipants?.includes(myProfile?.username)}
              />
              <p>In your calls, please ask:</p>
              <ul>
                <li>What climate action do you want to take?</li>
                <li>How can we support each other?</li>
                <li>What’s the next step?</li>
              </ul>
              <p>..and then repeat in other calls. When you're done, press the red button above and record your Action Pledge.</p>
            </>
          }
          {calls.filter(i => i.id != callRoom?.id && i.isOpen && i.invitees?.includes(myProfile?.username)).length > 0 && 
            <>
              <p>You have invite(s):</p>
              {calls
              .filter(i => i.id != callRoom?.id && i.isOpen && i.invitees?.includes(myProfile?.username))
              .sort((a,b) => {
                // If one is a current call, bump it up
                if (a.isExternal - b.isExternal != 0) return a.isExternal - b.isExternal;
                // Else, keep the sort consistent by sorting by hash
                return a.id < b.id && 1 || -1;
              })
              .map(invite => {
                let filteredParticipants = invite.currentParticipants?.filter(i => i != myProfile?.username && i != invite.creator);
                let thisMeetingInfo = invite.externalLink? meetingInfo(invite.externalLink) : null;
                return (
                  <CallRoomItem
                    callRoom={invite}
                    myProfile={myProfile}
                    isMyRoom={invite.creator == myProfile?.username}
                    userProfileIfExist={(username) => userProfileIfExist(username, matches)}
                    meetingInfo={meetingInfo}
                    joinCall={joinCall}
                    leaveCall={leaveCall}
                    isInvite={true}
                    isCurrentlyInCall={false}
                    isCurrentlyInAnyCall={!!callRoom}
                    enableRealTimeButtons={(hub?.state === HubConnectionState.Connected)}
                  />
                );
                
              })}
            </>
          }
          <p>These are your matches:</p>
          <div>
            {
              matches
              ?.filter(m => m.lastSeen && m.lastSeen > new Date(new Date().getTime() - (6*60*1000)).toISOString())
              ?.map(m => ({
                ...m,
                inACall: (calls.find(c => c.isOpen && c.currentParticipants?.includes(m.user)) != null)
              }))
              ?.sort((a,b) => {
                // If one is a current call, bump it up
                // if (a.inACall) return 1;
                // if (b.inACall) return -1;
                // Else, if one has a higher score, bump it up
                if (a.score < b.score) return 1;
                if (b.score < a.score) return -1;
                // Else, keep the sort consistent by sorting by hash
                return a.user < b.user && 1 || -1;
              })
              .map(m => {
                // Find an invite from this user
                let userOtherCall = calls?.find(i => i.isOpen && (i.creator == m.user || i.currentParticipants?.includes(m.user)))
                // TODO rework this line to make efficient use of a subquery of above
                let existingInviteWithUser = calls?.find(i => i.isOpen && i.invitees?.includes(myProfile?.username) && (i.creator == m.user || i.currentParticipants?.includes(m.user)))
                return (
                  <CallRoomItem
                    match={m}
                    myProfile={myProfile}
                    userProfileIfExist={(username) => userProfileIfExist(username, matches)}
                    meetingInfo={meetingInfo}
                    joinCall={joinCall}
                    createWithUser={createWithUser}
                    enableRealTimeButtons={(hub?.state === HubConnectionState.Connected)}
                    isCurrentlyInAnyCall={!!callRoom}
                    matchIsInOtherCall={!!userOtherCall}
                    currentlyOngoingCall={callRoom}
                    inviteToCall={inviteToCall}
                    uninviteFromCall={uninviteFromCall}
                    existingInviteWithUser={existingInviteWithUser}
                  />
                )
                
                // <div className="match-result-item">
                //   <img className="avatar" src={m.profilePicture?.length ? m.profilePicture : process.env.PUBLIC_URL + '/guest.png'} onError={e => {e.target.onError=null; e.target.src = process.env.PUBLIC_URL + '/guest.png'}} />
                //   <div style={{marginLeft:"48px", marginRight:"122px"}}>
                //     <span className="match-result-item-name">{m.nickname || m.user} 
                //       {m.inACall && <small className="ml-1">(in a call)</small>}
                //       {m.summary?.length > 0 && <small className="ml-2">{m.summary}</small>}
                //     </span>
                //     {m.wantsToTalk?.length && <span className="match-result-item-reason">Wants to talk about {m.wantsToTalk}</span>}
                //     {(existingInviteWithUser && existingInviteWithUser.creator != m.user) &&
                //       <span className="match-result-item-reason">
                //         Currently in a call with {matches?.find(m => m.user == existingInviteWithUser.creator)?.nickname || existingInviteWithUser.creator} where you are invited
                //       </span>
                //     }
                //     <span className="match-result-item-reason">{m.reason}</span>
                //   </div>
                //   {!callRoom && (
                //     existingInviteWithUser ? 
                //       <button
                //         type="button"
                //         className="match-result-item-call btn btn-primary btn-sm"
                //         onClick={joinCall}
                //         data-call={existingInviteWithUser.id}
                //         disabled={!(hub?.state === HubConnectionState.Connected)}
                //         data-username={m.user}>Join ongoing call</button>
                //     :
                //       <button
                //         type="button"
                //         className="match-result-item-call btn btn-secondary btn-sm"
                //         onClick={createWithUser}
                //         disabled={!(hub?.state === HubConnectionState.Connected)}
                //         data-username={m.user}>Call {myProfile?.useExternalVideo && `(${meetingInfo(myProfile.externalCallLink)?.serviceName || 'external'})`}</button>
                //   )}
                //   {callRoom && callRoom.creator == myProfile?.username && (
                //     callRoom?.invitees?.includes(m.user)?
                //       <button
                //         type="button"
                //         className="match-result-item-call btn btn-secondary btn-sm" 
                //         onClick={uninviteFromCall}
                //         disabled={!(hub?.state === HubConnectionState.Connected)}
                //         data-username={m.user}
                //       >
                //         Uninvite
                //       </button>
                //     :
                //       <button
                //         type="button"
                //         className="match-result-item-call btn btn-secondary btn-sm" 
                //         onClick={inviteToCall}
                //         disabled={!(hub?.state === HubConnectionState.Connected)}
                //         data-username={m.user}
                //       >
                //         Invite {myProfile?.useExternalVideo && `(${meetingInfo(myProfile.externalCallLink)?.serviceName || 'external'})`}
                //       </button>
                //   )}
                // </div>;
              }
              )
            }
          </div>
        </div>
      </div>
      {callRoom && !callRoom.isExternal && myProfile && myProfile.username && (
        <div className={cx(styles.callContainer)}>
          <iframe allow="camera;microphone;display-capture" style={{border: "none", width: "100%", height: "100%"}} src={`${callEndpoint}/room/${callRoom.id}?username=${encodeURIComponent(myProfile.username)}&adhoc`}></iframe>
        </div>
      )}
      {callRoom && callRoom.isExternal && myProfile && myProfile.username && callRoom.creator != myProfile.username && (
        <ExternalCallJoinModal
          callRoom={callRoom}
          creatorInfo={matches?.find(m => m.user == callRoom.creator)}
          onClose={leaveCall}
        />
      )}
    </>
  )

}

export default MatcherRoom;