import React, { useState, useEffect, useRef } from 'react';

import styles from './CallRoomItem.module.css';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

let cx = classNames.bind(styles);

function CallRoomItem(props) {

  const filteredParticipants = props.callRoom?.currentParticipants?.filter(p => p != props.myProfile?.username && p != props.callRoom.creator);
  const thisMeetingInfo = props.callRoom?.externalLink? props.meetingInfo(props.callRoom.externalLink) : null;
  const ownerProfile = props.match || (props.isMyRoom? props.myProfile : props.userProfileIfExist(props.callRoom.creator));
  console.log(filteredParticipants);
  return (
    <div className={cx(styles.matchItem, "clearfix")}>
      <img
        className={styles.matchAvatar}
        src={ownerProfile?.profilePicture?.length ? ownerProfile?.profilePicture : process.env.PUBLIC_URL + '/guest.png'}
        onError={e => {e.target.onError=null; e.target.src = process.env.PUBLIC_URL + '/guest.png'}}
      />
      <div className={styles.matchTextContent}>
        <div className={cx(styles.nameAndRole, "mb-1")}>
          <span className={styles.matchName}>
            {props.isMyRoom? "Your room" : `${ownerProfile?.nickname || ownerProfile?.username || props.callRoom?.creator}`} 
          </span>
          {!props.isMyRoom && ownerProfile?.summary &&
            <small className="ml-1">{ownerProfile?.summary}</small>
          }
        </div>
        {!props.isMyRoom && ownerProfile?.wantsToTalk?.length >0 &&
          <span className={cx(styles.matchSubLine, styles.wantsToTalkLine)}>
            Wants to discuss {ownerProfile?.wantsToTalk}
          </span>
        }
        {!props.isMyRoom && ownerProfile?.reason?.length >0 &&
          <span className={cx(styles.matchSubLine, styles.matchReasonLine)}>
            {ownerProfile?.reason}
          </span>
        }
      
        {filteredParticipants?.length > 0 &&
          <span className={cx(styles.matchSubLine, styles.otherUsersLine)}>
            also with {filteredParticipants.map(p => (props.userProfileIfExist(p)?.nickname) || p).join(', ')}
          </span>
        }
        {
          props.matchIsInOtherCall && !props.existingInviteWithUser &&
          <span className={cx(styles.matchSubLine, styles.otherUsersLine)}>
            In another call
          </span>
        }
        {
          props.existingInviteWithUser && props.existingInviteWithUser.creator != props.match?.user &&
          <span className={cx(styles.matchSubLine, styles.otherUsersLine)}>
            Currently in a call with {props.userProfileIfExist(props.existingInviteWithUser.creator)?.nickname || props.existingInviteWithUser.creator} where you are invited
          </span>
        }
        {
          props.existingInviteWithUser && props.existingInviteWithUser.creator == props.match?.user &&
          <span className={cx(styles.matchSubLine, styles.otherUsersLine)}>
            {props.userProfileIfExist(props.existingInviteWithUser.creator)?.nickname || props.existingInviteWithUser.creator} has already invited you
          </span>
        }
      </div>
      {props.isCurrentlyInCall &&
        <button
          type="button"
          className="match-result-item-call btn btn-danger btn-sm"
          onClick={props.leaveCall}
          data-call={props.callRoom.id}
          disabled={!props.enableRealTimeButtons}
        >
          {props.isMyRoom? (props.callRoom.isExternal? "Mark Call as Ended" : "End Call") : "Leave Call"}
        </button>
      }
      {props.isInvite && !props.isCurrentlyInAnyCall && 
        <button
          type="button"
          className="match-result-item-call btn btn-primary btn-sm"
          onClick={props.joinCall}
          data-call={props.callRoom.id}
          disabled={!props.enableRealTimeButtons}
        >
            {thisMeetingInfo?.icon && <FontAwesomeIcon icon={thisMeetingInfo.icon} />} Join {props.callRoom.externalLink && ` ${thisMeetingInfo?.serviceName || '(external)'}`}
        </button>
      }
      {props.match && !props.isCurrentlyInAnyCall &&
        (props.existingInviteWithUser?
          <button
            type="button"
            className="match-result-item-call btn btn-primary btn-sm"
            onClick={props.joinCall}
            data-call={props.existingInviteWithUser.id}
            disabled={!props.enableRealTimeButtons}
            data-username={props.match?.user}>
              Join ongoing call
            </button>
        :
          <button
            type="button"
            className="match-result-item-call btn btn-secondary btn-sm"
            onClick={props.createWithUser}
            disabled={!props.enableRealTimeButtons}
            data-username={props.match?.user}>Call {props.myProfile?.useExternalVideo && `(${props.meetingInfo(props.myProfile.externalCallLink)?.serviceName || 'external'})`}</button>
        )}
        {props.isCurrentlyInAnyCall && props.currentlyOngoingCall?.creator == props.myProfile?.username && (
          props.currentlyOngoingCall?.invitees?.includes(props.match.user)?
            <button
              type="button"
              className="match-result-item-call btn btn-secondary btn-sm" 
              onClick={props.uninviteFromCall}
              disabled={!props.enableRealTimeButtons}
              data-username={props.match.user}
            >
              Uninvite
            </button>
          :
            <button
              type="button"
              className="match-result-item-call btn btn-secondary btn-sm" 
              onClick={props.inviteToCall}
              disabled={!props.enableRealTimeButtons}
              data-username={props.match.user}
            >
              Invite {props.myProfile?.useExternalVideo && `(${props.meetingInfo(props.myProfile.externalCallLink)?.serviceName || 'external'})`}
            </button>
        )}
    </div>
  )
}
export default CallRoomItem;