import React, { useState, useEffect, useRef } from 'react';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import renderTreeSearchOption from './renderTreeSearchOption';
import Fuse from 'fuse.js';

export default function TreeSearch(props) {

  const [value, setValue] = useState(null);

  useEffect(() => {
    value && props.onChanges(value);
    setValue(null);
  }, [value])

  return (
    <SelectSearch
      {...props}
      value={value}
      onChange={setValue}
      filterOptions={(options) => {
        return (value) => {
          if (!value.length) {
            return options;
          }
          return options.map(option => {
            if (!option.type) return option;
            const fuse = new Fuse(option.items, {
              keys: ["name", "value"],
              threshold: 0.3
            });
            return {
              ...option,
              items: fuse.search(value)
            };
          })
        };
      }}
      renderOption={renderTreeSearchOption}
    />
    )
}