import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import * as microsoftTeams from "@microsoft/teams-js";

export default function TeamsConfig(props) {

  const [log, setLog] = useState(["Test log"]);
  const [context, setContext] = useState(null);

  let { surveyId } = useParams();

  useEffect(() => {
    microsoftTeams.initialize();
    microsoftTeams.appInitialization.notifySuccess();
    microsoftTeams.settings.registerOnSaveHandler(function (saveEvent) {
      microsoftTeams.settings.setSettings({
        entityID: "andeye",
        contentUrl: `${window.location.origin}/sharedtree/61b32661604b6b9a2d527201`,
        suggestedTabName: "andeye",
        websiteUrl: `${window.location.origin}/sharedtree/61b32661604b6b9a2d527201`,
      });
      saveEvent.notifySuccess();
    });
    microsoftTeams.settings.setValidityState(true);
    microsoftTeams.getContext(setContext)
    
  }, []);





  if (context) {
    return <h1>{context['upn']}</h1>
  }
  return (
    null
  )

}