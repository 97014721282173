import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState
} from '@microsoft/signalr';
import styles from './ProfileBar.module.css';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileSignature, faQuestionCircle, faUsers } from '@fortawesome/free-solid-svg-icons';
import { Modal } from 'reactstrap';
import ProfileEditModal from './ProfileEditModal';
let cx = classNames.bind(styles);

function ProfileBar(props) {

  const [profileEditModalVisible, setProfileEditModalVisible] = useState(false);

  const handleCloseProfileEditModal = (profile) => {
    setProfileEditModalVisible(false);
    if (profile) {
      props.setMyProfile(profile);
      fetch(`/api/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(profile)
      })
    }
  }

  return (props.myProfile &&
    <>
      <div className={cx("text-right", {"col-6 col-md-4 py-2": !props.noColumns}, {'underline-on-hover': props.clickable})} onClick={e => {if (props.clickable) setProfileEditModalVisible(true)}}>
        <img className="avatar float-right ml-2" src={props.myProfile.profilePicture || process.env.PUBLIC_URL + '/guest.png'} onError={e => {e.target.onError=null; e.target.src = process.env.PUBLIC_URL + '/guest.png'}} />
        <span className={styles.myProfileNickname}>{props.myProfile.nickname || props.myProfile.username}</span>
        <span className={cx(styles.myProfileSummary, {noSummary: !props.myProfile.summary?.length})}>{props.myProfile.summary ? props.myProfile.summary : <i>Enter your role</i>}</span>
      </div>
  
      {profileEditModalVisible &&
        <ProfileEditModal
          myProfile={props.myProfile}
          onClose={handleCloseProfileEditModal}
        />
      }
    </>
            
  );

}

export default ProfileBar;