import React, { Component } from 'react';
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export class SuggestionQuestion extends Component {
  static displayName = SuggestionQuestion.name;

  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      currentValue: props.question.response || ""
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleNewSuggestion = this.handleNewSuggestion.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    
    //Workaround
    if (this.state.currentValue == 0) {
      this.state.onValueChange(this.props.question.questionText, this.state.currentValue);
    }
  }

  handleChange(event) {
    this.setState({currentValue: event.target.value}, () => {
      this.state.onValueChange(this.props.question.questionText, this.state.currentValue);
    });
    
    //this.forceUpdate();
  }

  handleDelete(event) {
    // This is a bit of a workaround currently as the array in which these elements is presented is causing a React state leak.
    this.state.onDelete(this.state.question.questionText);
    // this.setState({currentValue: "DELETED"}, () => {
    //   this.state.onValueChange(this.props.question.questionText, this.state.currentValue);
    // });
  }

  handleNewSuggestion(event) {
    this.state.handleNewSuggestion(event);
  }

  render() {
      if (this.state.currentValue == "DELETED") {
        return null;
      }
      if (this.state.mode == "view") {
        return (
          <div class="suggestion-view d-inline-block">
            <b>Suggestion:</b> {this.state.question.response}
          </div>
        )
      }
      return (
      <div className="form-group">
        <div className="input-group suggestion-input">
          <div className="input-group-prepend">
            <span className="input-group-text">Suggestion</span>
          </div>

          <input autoFocus={this.state.focus} maxLength={this.state.maxLength} value={this.state.currentValue} onChange={this.handleChange} type="text" className="form-control" placeholder="Suggest a topic..."/>
          {this.state.currentValue.length?
          <span className="ml-3 mt-2" onClick={this.handleNewSuggestion} data-parent={this.state.question.questionText}>
            <FontAwesomeIcon icon={faPlus} />
          </span>
          :null}
          <span className="ml-3 mt-2" onClick={this.handleDelete}>
            <FontAwesomeIcon icon={faTrashAlt} />
        </span>
        </div>
        
        {this.state.currentValue.length == this.state.maxLength?
            <small className="form-text text-muted">Maximum text length reached</small>
          : null}
      </div>
          
      )
  }


}