import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';

import styles from './PersonalRecorder.module.css';
import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import ProfileBar from '../survey/ProfileBar';
import { faAt } from '@fortawesome/free-solid-svg-icons';
import GuestLoginModal from '../auth/GuestLoginModal';

let cx = classNames.bind(styles);

export default function PersonalRecorder(props) {
  const callEndpoint = "https://interactive.andeye.com:9443";

  const [myProfile, setMyProfile] = useState(null);
  const [showGuestModal, setShowGuestModal] = useState(null);

  useEffect(() => {
    populateMyProfile()
  },[]);

  const populateMyProfile = async () => {
    fetch(`/api/user`)
    .then(response => {
      if (response.status == 200) {
        response.json()
        .then(data => setMyProfile(data))
      }
      else if (response.status == 401) {
        console.log(401);
        setShowGuestModal(true);
      }      
    })
  }
  console.log(showGuestModal);

  return (
    <>
      <div className={styles.infoContainer}>
        {myProfile &&
          <ProfileBar
            clickable={true}
            myProfile={myProfile}
            setMyProfile={setMyProfile}
            noColumns={true}
          />
        }
        {showGuestModal &&
        <GuestLoginModal
          show={true}
        />
        }
        <h1 className="mt-5">Action Pledge Recorder</h1>
        <p>Please try to keep your pledge as succinct as possible - ideally 5-10 seconds.</p>
        <p>Please start your Action Pledge by saying "I’m [name] and I pledge to...”</p>
        <p>To record:</p>
        <ol>
          <li>Ensure the system on the right of the page can see and hear you</li>
          <li>Click 'Join Now'</li>
          <li>When you're ready to record your Pledge, use the 'Start Recording' button</li>
          <li>Compose yourself for ~1 second then make your pledge</li>
          <li>Press 'Stop Recording'  ~1 second after you're finished. The recording will be saved to our system.</li>
          <li>If you'd like to have another go, repeat steps 3-5 until you're happy with your Pledge.</li>
        </ol>
        <p>
          If you cannot access the video handler to record, you can upload your pledge:
          <a
            className="btn btn-info btn-sm ml-2"
            href="https://wcd.andeye.com/upload"
            target="_blank"
          >
            <FontAwesomeIcon icon={faDropbox}/> Upload your Pledge
          </a>
        </p>
        <p>
          If neither work for you, please get in touch:
          <a
            className="btn btn-info btn-sm ml-2"
            href="mailto:help@andeye.com"
            target="_blank"
          >
            <FontAwesomeIcon icon={faAt}/> Email help@andeye.com
          </a>
        </p>
        <p>
        By submitting a pledge you give andeye Productions Ltd and their assigns permission in perpetuity to edit, collate and distribute your pledge via any medium.
        </p>
      </div>
      {myProfile && <div className={cx(styles.callContainer)}>
        <iframe allow="camera;microphone;display-capture" style={{border: "none", width: "100%", height: "100%"}} src={`${callEndpoint}/room/rec_${myProfile.id}?username=${myProfile.username}&adhoc&recorder`}></iframe>
      </div>}
    </>
  )
}
