import { faCheck, faPlus, faStop, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { Button } from 'reactstrap';
import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState
} from '@microsoft/signalr';

import QuestionRow from './QuestionRow'

function RoundEditor(props) {

  // Store of the questions in the table
  const [questions, setQuestions] = useState([]);
  // Store of question responses for the active question if one is selected
  const [questionResponses, setQuestionResponses] = useState([]);
  // Store of room data. The room is modified when an active question is attached to it.
  const [room, setRoom] = useState(null);

  let history = useHistory();

  // The SignalR hub. lastHubState is used in a useEffect to ensure the hub stays connected.
  const [hub, setHub] = useState(null);
  const lastHubState = useRef(null);

  // This parameter comes from the URL, /admin/quiz/[roomId].
  // It is optional and none of the quiz-running functions appear if it is omitted.
  let {roomId} = useParams();

  // Upon load of the page
  useEffect(() => {
    // Get all quiz questions and save them in the questions array
    fetch(`/api/quizquestion`, {credentials: 'include'})
      .then(response => response.json())
      .then(data =>
        setQuestions(data)
      );
    // Connect to the SignalR hub
    const hubConnection = new HubConnectionBuilder()
      .withUrl("/controlHub")
      .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
      .configureLogging(LogLevel.Information)
      .build();
    hubConnection
      .start()
      .then(() => {
        setHub(hubConnection);
      });
  },[]);

  // Runs if anything about the hub changes. Checks if it is connected and resets the handlers if it (re)connects.
  useEffect(() => {
    if (hub?.state === HubConnectionState.Connected && hub.state != lastHubState.current) {
      hub.invoke("subscribeQuizmaster");
      hub.off("modifyRoom");
      hub.on("modifyRoom", (id, room) => {
        if (id == room?.id) {
          setRoom(room)
        }
      });
      hub.off("newQuestionResponse");
      hub.on("newQuestionResponse", (questionResponse) => {
        setQuestionResponses(prev => ([...prev.filter(qr => qr.id !== questionResponse.id), questionResponse]))
      });
      lastHubState.current = hub.state;
    }
    
  },[hub]);

  // Runs when the active quiz question changes
  useEffect(() => {
    // If there is an active quiz question, get all existing responses to the question
    if (room?.activeQuizQuestion) {
      fetch(`/api/quizquestion/${room.activeQuizQuestion}/response`, {credentials: 'include'})
      .then(response => response.json())
      .then(data =>
        setQuestionResponses(data)
      );
    }
  }, [room?.activeQuizQuestion])

  // Runs whenever any questions change
  useEffect(() => {
    // Look through for any questions with 'changed' set. This means 'please push me to the database'.
    questions.filter(q => q.changed).forEach(q => {
      fetch(`/api/quizquestion/${q.id}`, {
        credentials: 'include',
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(q)
      });
      // Update the questions array. Keep all the other questions, and modify this question to no longer have 'changed' set.
      setQuestions(prev => [...prev.filter(qu => qu.id != q.id), {...q, changed: false}]);
    })
  }, [questions])
  
  // If there is a roomId parameter in the URL, get the room information.
  useEffect(() => {
    if (roomId) {
      fetch(`/api/room/${roomId}`, {credentials: 'include'})
      .then(response => response.json())
      .then(data =>
        setRoom(data)
      )
    }
  },[roomId])

  // Handles the creation of a new (blank) question, pushes this to the database and then adds it to the array for editing
  const handleNewQuestion = (e) => {
    e.preventDefault();
    fetch(`/api/quizquestion`, {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({type: "mcq", answers: [], mcqOptions: []})
    })
    .then((response) => response.json())
    .then((data) => setQuestions([...questions, data]))
  }

  // Handles deleting a question
  const handleDelete = (id) => {
    fetch(`/api/quizquestion/${id}`, {
      credentials: 'include',
      method: 'DELETE'
    }).then((result) => {
      if (result.ok) {
        setQuestions(prev => prev.filter(q => q.id != id));
      }
    })
  }

  // 'Stop' a question, by setting the active question in the room to null, returning it to the tree/elsewhere
  const stopQuestion = () => {
    return fetch(`/api/room/${roomId}/quiz`, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: props.roomId,
        activeQuizQuestion: null
      })
    })
  }

  // Sends a real-time 'reveal answer' message to the clients.
  const revealAnswer = () => {
    hub.invoke("revealAnswer", roomId)
  }

  // Just a shorthand to get the current active question to make code tidier
  const activeQuestion = () => {
    return questions.find(q=>q.id == room.activeQuizQuestion)
  }

  return (
    <div>
      <h1>Editing quiz questions</h1>
        {// Only run this section if there is a room ID in the URL
        roomId && (
          <>
            <h2>Selected room</h2>
            {!room &&
              <p>Loading room information</p>
            }
            {room && !room.activeQuizQuestion &&
              <p>No quiz question currently running. Run one from below.</p>
            }
            {// If there is an active quiz question, display it and its responses
            room?.activeQuizQuestion && activeQuestion() &&
              <>
                <p>{activeQuestion()?.questionText}</p>
                <ul>
                  {questionResponses
                  .filter(qr => qr.question == room.activeQuizQuestion)
                  .map(qr => (
                    <li>{qr.answer} ({qr.username}) 
                    {activeQuestion().answers.map(a=>a.trim().toLowerCase()).includes(qr.answer.trim().toLowerCase())?
                      <FontAwesomeIcon icon={faCheck} color="green"/>
                    :
                    <>
                      <FontAwesomeIcon icon={faTimes} color="red"/>
                      
                      <Button
                        color='success'
                        className='ml-1'
                        size='sm'
                        onClick={e => {
                          e.preventDefault();
                          let answers = activeQuestion().answers || [];
                          if (!answers.includes(qr.answer)) answers.push(qr.answer);                          
                          setQuestions(prev => [...prev.filter(q=> q.id != activeQuestion().id), {...activeQuestion(), answers, changed: true}])}}
                      >
                        <FontAwesomeIcon icon={faCheck}/> Mark correct
                      </Button>
                    </>
                    }
                    
                    </li>
                  ))}
                </ul>
                <Button color="secondary" onClick={stopQuestion}>
                  <FontAwesomeIcon icon={faStop}/> Stop Question
                </Button> <Button color="success" onClick={revealAnswer}>
                  <FontAwesomeIcon icon={faCheck}/> Reveal answer
                </Button> 
              </>
            }
          </>
        )}
        <h2>Questions</h2>
        <table className="table">
        <thead>
          <tr>
            <th>Question</th>
            <th>Type</th>
            <th>Answer</th>
            {/* <th>Points</th> */}
            <th></th>
          </tr>
        </thead>
        <tbody>
          {// Questions in ID order (creation order; not having an order would cause them to move around when editing)
          questions.sort((a,b) => (a.id > b.id && 1 || -1)).map(question => {
            console.log(question);
            // Use QuestionRow component to display each question editor
            return <QuestionRow
              key={question.id}
              question={question}
              // This setQuestion function filters the question out of the array and replaces it
              setQuestion={q => setQuestions(prev => [...prev.filter(qu => qu.id != q.id), q])}
              handleDelete={handleDelete}
              roomId={roomId}
              currentlyActive={question.id == room?.activeQuizQuestion}
            />
          })}
        </tbody>
      </table>
      <Button color="primary" onClick={handleNewQuestion}>
        <FontAwesomeIcon icon={faPlus}/> New Question
      </Button>
    </div>
  )
}

export default RoundEditor;