import React, { Component } from 'react';

export class CallWindow extends Component {
  static displayName = CallWindow.name;

  constructor(props) {
    super(props);
    this.state = {
      ...this.props
    };
    

    this.onHangUp = this.onHangUp.bind(this);
    this.onExitCallWindow = this.onExitCallWindow.bind(this);
  }

  onHangUp(event) {
    this.setState({feedback: 'awaiting'})
  }

  onExitCallWindow(event) {

    event.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        event: "Sample event", // TODO fix reference
        feedbackType: event.target.innerText,
        reason: this.state.match.reason,
      })
    }
    console.log(this.state.response);
    fetch('/api/feedback', requestOptions);
    
    this.state.onHangUp();
  }

  render() {
    let contents = <div>This call is invalid</div>
    if (this.state.feedback) {
      contents = (
        <div className="call-window call-window-feedback">
          <p>How did you find this interaction? Your anonymous feedback helps tailor future interactions.</p>
          <p>
            <span className="call-window-feedback-button call-window-feedback-button-positive" onClick={this.onExitCallWindow}>Positive</span>
            <span className="call-window-feedback-button call-window-feedback-button-negative"onClick={this.onExitCallWindow}>Negative</span>
          </p>
        </div>
      )
    }
    else if (this.state.match) {
      let callframeurl = "https://calls.joel.pm/room/" + this.state.match.pairId;
      contents = (
        <div className="call-window">
          <div className="call-window-iframe-container">
            <iframe className="call-window-iframe" allow="autoplay;camera;microphone" src={callframeurl}></iframe>
          </div>
          <p className="call-window-helper">Press the settings cog to change your webcam/mic settings. <span onClick={this.onHangUp} className="call-window-hangup">Hang Up</span></p>

        </div>
      )
    }

    return contents;
  }
}