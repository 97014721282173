import React, { Component } from 'react';

import { Link, Redirect } from 'react-router-dom';

export class NewSurveyResponse extends Component {
  static displayName = NewSurveyResponse.name;

  constructor(props) {
    super(props);
    this.state = { loading: true};
    this.createSurveyResponse = this.createSurveyResponse.bind(this);
  }

  componentDidMount() {
    this.createSurveyResponse();
  }

  render () {
    if (this.state.createdSurveyResponse) {
      return <Redirect to={'/surveyresponse/' + this.state.createdSurveyResponse} />
    }

    let contents = <p><em>Creating new survey response...</em></p>;

    return (
      <div>
        {contents}
        {this.state.error?
            <p className="alert alert-danger">{this.state.error}</p>
          :null}
      </div>
    );
  }



  async createSurveyResponse() {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"survey": this.props.match.params.id})
    }
    const response = await fetch('/api/surveyresponse', requestOptions);
    if (!response.ok) {
      let message = <span>There was an unexpected error. Please refresh, try again later or contact andeye at info@andeye.com for assistance</span>
      if (response.status == 401) {
        message = <span>We couldn't find your login credentials. Go to the registration page, ensure you are logged in, and return to this event.</span>
      }
      this.setState({error: message})
    }
    else {
      const surveyResponse = await response.json();
      this.setState({createdSurveyResponse: surveyResponse.id});
    }
    
  }
}
