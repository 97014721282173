import React, { useState, useEffect } from 'react';

function TwilioInfo(props) {

  const [participants, setParticipants] = useState(null);

  useEffect(() => {
    if (props.twilioRoom){
      fetch(`https://interactive.andeye.com:9443/twilio/rooms/${props.twilioRoom.sid}/participants`)
      .then(response => response.json())
      .then(data => {
        setParticipants(data);
      });
    }
  },[props.twilioRoom]);

  return <div>
    <p>{participants?.length}</p>
    {participants?.map(p => 
      <div>{p.identity}</div>
    )}
  </div>;
}


export default TwilioInfo;