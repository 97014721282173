import React, { useEffect, useRef, useState } from 'react';
function YouTubePlayer(props) {
  
  const playerRef = useRef(null)
  const player = useRef(null);
  const [muted, setMuted] = useState(true);

  return (
    <div className="cocreation-vimeo">
      <iframe
        src={`https://www.youtube.com/embed/${props.options.id}?autoplay=1`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </div>
  )
}

export default YouTubePlayer;