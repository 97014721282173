import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { CheckboxQuestion } from './CheckboxQuestion';

export class ViewSurvey extends Component {
  static displayName = ViewSurvey.name;

  constructor(props) {
    super(props);
    this.state = { loading: true, newQuestion: ''};
    this.handleChange = this.handleChange.bind(this);
    this.addQuestion = this.addQuestion.bind(this);
    this.renderSurveyData = this.renderSurveyData.bind(this);
    this.createSurveyResponse = this.createSurveyResponse.bind(this);
  }

  componentDidMount() {
    this.populateSurveyData();
  }

  renderSurveyData(survey) {
    let questionitems = [];
    return (
      <div>
        <h2>Survey for event: /{survey.event}</h2>
        <p>Landing message: <em>{survey.message}</em></p>
        <h3>Questions</h3>
        <div>
          {survey.questions.map(question => <CheckboxQuestion question={question} mode="view"/>)}
        </div>
        <form onSubmit={this.addQuestion}>
          <div className="form-group">
            <label for="newQuestion">New question</label>
            <input value={this.state.newQuestion} onChange={this.handleChange} type="text" className="form-control" id="newQuestion" placeholder="Enter new question"/>
          </div>
          <button type="submit" className="btn btn-primary">Add</button>
        </form>
        <br/>
        <a type="submit" className="link-primary" onClick={this.createSurveyResponse}>Complete this survey (dev)</a>
      </div>
    );
  }

  async createSurveyResponse(event) {
    event.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"survey": this.state.survey.id})
    }
    const response = await fetch('/api/surveyresponse', requestOptions);
    const surveyResponse = await response.json();
    this.setState({createdSurveyResponse: surveyResponse.id});
    
  }


  handleChange(event) {
    this.setState({newQuestion: event.target.value});
  }

  async addQuestion(event) {
    event.preventDefault();
    this.state.survey.questions.push({
      questionText: this.state.newQuestion,
      type: "checkbox"
    })
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(this.state.survey)
    }
    console.log(this.state.survey);
    const response = await fetch('/api/survey/' + this.props.match.params.id, requestOptions);
    this.setState({newQuestion: ''});
    this.populateSurveyData();
  }

  render() {

    if (this.state.createdSurveyResponse) {
      return <Redirect to={'/surveyresponse/' + this.state.createdSurveyResponse} />
    }

    let contents = this.state.loading
      ? <p><em>Loading Networking Survey...</em></p>
      : this.renderSurveyData(this.state.survey);

    return (
      <div>
        {contents}
      </div>
    );
  }

  async populateSurveyData() {
    const response = await fetch('api/survey/' + this.props.match.params.id);
    const data = await response.json();
    this.setState({ survey: data, loading: false });
  }
}
