import React, { Component, useContext } from 'react';


import {
  HubConnectionBuilder,
  LogLevel,
  HubConnectionState
} from '@microsoft/signalr';

import cx from 'classnames'
import ReactMarkdown from 'react-markdown';

import { faPaperPlane, faPlus, faCheckCircle, faExclamationCircle,
         faLayerGroup, faCut, faPaste, faChessPawn, faChessKing,
         faBullseye, faLevelUpAlt, faRedo, faCommentDots,
         faVideoSlash, faMicrophoneSlash, faVideo, faSortDown, faMinus, faExchangeAlt, faExternalLinkSquareAlt, faThumbsUp as faSolidThumbsUp, faPlay, faChevronRight, faChevronCircleRight, faLanguage, faSearch, faLevelDownAlt, faClipboard, faCaretDown, faCaretUp }
         from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt, faThumbsUp as faRegularThumbsUp } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ConsoleLogger } from '@microsoft/signalr/dist/esm/Utils';
import { SliderQuestion } from '../survey/SliderQuestion';
import CommentViewer from './CommentViewer';
import { Redirect } from 'react-router';
import Diff from 'text-diff';
import SelectSearch, { fuzzySearch } from 'react-select-search';
import './TreeSearch.css';
import VimeoPlayer from '../vimeo/VimeoPlayer';
import ReactTooltip from 'react-tooltip';
import GuestLoginModal from '../auth/GuestLoginModal';
import CorrectTranslationModal from '../translate/CorrectTranslationModal';
import WCDHeader from '../branding/WCDHeader';
import TreeLink from '../markdown/TreeLink';
import TreeSearch from './TreeSearch';
import LanguageSelect, { langName } from './LanguageSelect';
import { Alert } from 'reactstrap';
import YouTubePlayer from '../vimeo/YouTubePlayer';
import * as microsoftTeams from "@microsoft/teams-js";
import QuestionPlayer from '../quiz/QuestionPlayer';


export class SharedTree extends Component {
  static displayName = SharedTree.name;

  constructor(props) {
    super(props);
    this.handleNewItem = this.handleNewItem.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleRenameChange = this.handleRenameChange.bind(this);
    this.handleRenameQuestion = this.handleRenameQuestion.bind(this);
    this.handleSelectItem = this.handleSelectItem.bind(this);
    this.handleSelectEditModeItem = this.handleSelectEditModeItem.bind(this);
    this.handleDeleteItem = this.handleDeleteItem.bind(this);
    this.handleModifyItem = this.handleModifyItem.bind(this);
    this.handleViewComments = this.handleViewComments.bind(this);
    this.handleVote = this.handleVote.bind(this);
    this.handleCutItem = this.handleCutItem.bind(this);
    this.handlePasteItem = this.handlePasteItem.bind(this);
    this.handleUpdateViewRoot = this.handleUpdateViewRoot.bind(this);
    this.handleSliderChange = this.handleSliderChange.bind(this);
    this.handleSlideStop = this.handleSlideStop.bind(this);
    this.createConnection = this.createConnection.bind(this);
    this.renderQuestions = this.renderQuestions.bind(this);
    this.populateSurveyData = this.populateSurveyData.bind(this);
    this.populateResponseData = this.populateResponseData.bind(this);
    this.populateUserData = this.populateUserData.bind(this);
    this.switchModes = this.switchModes.bind(this);
    this.getQuestionAncestors = this.getQuestionAncestors.bind(this);
    this.ensureSubscribed = this.ensureSubscribed.bind(this);
    this.handleRankUser = this.handleRankUser.bind(this);
    this.handleRefreshUser = this.handleRefreshUser.bind(this);
    this.handleUserVisible = this.handleUserVisible.bind(this);
    this.handleAllocateRooms = this.handleAllocateRooms.bind(this);
    this.handlePullToHub = this.handlePullToHub.bind(this);
    this.handleRoomOpenStatus = this.handleRoomOpenStatus.bind(this);
    this.handleVideoClipStatus = this.handleVideoClipStatus.bind(this);
    this.handleChangeTreeMode = this.handleChangeTreeMode.bind(this);
    this.handleVideoClipClose = this.handleVideoClipClose.bind(this);
    this.handleRedirectAll = this.handleRedirectAll.bind(this);
    this.handleTwistyClick = this.handleTwistyClick.bind(this);
    this.handleLinkToQuestion = this.handleLinkToQuestion.bind(this);
    this.handleOpenTranslateModal = this.handleOpenTranslateModal.bind(this);
    this.handleCloseTranslateModal = this.handleCloseTranslateModal.bind(this);
  }

  componentDidMount() {
    console.log("mounted");
    const params = new URLSearchParams(window.location.search);
    const eventUrl = params.get('event')
    const treeOnly = params.get('tree-only')
    const branding = params.get('branding')
    const allowDark = params.get('dark')
    microsoftTeams.initialize();
    microsoftTeams.getContext(context => {
      if (context) {
        fetch(`/api/user/teamskey`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({email: context.loginHint, username: context.loginHint})
        })
        .then((response) => {
          if (response.ok) {
            window.location.reload();
          }
        })
      }
    })
    this.setState({
    newItemUnder: -1,
    editModeItem: -1,
    newItemContent: "",
    newItemType: null,
    newItemMaxLength: 50,
    viewingComments: null,
    newCommentContent: "",
    editModeRename: "",
    cutting: null,
    userList: [],
    redirect: null,
    userListOpen: false,
    videoClipOpen: false,
    callEndpoint: "https://interactive.andeye.com:9443",
    eventUrl,
    errors: [],
    showGuestModal: false,
    onGuestModalLogin: null,
    branding,
    language:null,
    viewRootOverride: false,
    openTranslationModal: null,
    searchSuggestionsOnly: false,
    allowDark,
    treeOnlyParam: (treeOnly != null)}, () => {
      this.populateUserData(this.createConnection);
      this.populateRoomData();
     
    });
  }

  componentDidUpdate() {
    
    ReactTooltip.rebuild();
  }


  createConnection() {
    const hubConnection = new HubConnectionBuilder()
    .withUrl('/controlHub')
    .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000])
    .configureLogging(LogLevel.Information)
    .build();
    this.setState({hubConnection: hubConnection}, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log("Connected to SignalR");
          if (this.state.eventUrl) {
            this.state?.user && this.state.hubConnection.
            invoke('eventArrival', this.state.eventUrl)
            .catch(err => this.addError({type: "warning", header: "Real-time connection", message: `There was an error while arriving in this room.`}));
          }
          this.forceUpdate();
          console.log("ALLOCATE");
          this.allocateMeIfRequired();
        })
        .catch(err => this.addError({type: "warning", header: "Real-time connection", message: "We encountered a problem establishing a real-time connection to the server"}));
      this.state.hubConnection.onreconnected(() => {
        this.populateRoomData();
      })
      this.state.hubConnection.on('newQuestion', (id, receivedQuestion) => {
        if (id == this.state?.survey?.id) {
          const survey = this.state.survey;
          survey.questions.push(receivedQuestion)
          this.setState({ survey });
        }
      });
      this.state.hubConnection.on('deleteQuestion', (id, receivedQuestion) => {
        if (id == this.state?.survey?.id) {
          const survey = this.state.survey;
          survey.questions = survey.questions.filter(q => q.questionText !== receivedQuestion.questionText);
          this.setState({ survey });
        }
      });
      this.state.hubConnection.on('modifyQuestion', (id, receivedQuestion) => {
        if (id == this.state?.survey?.id) {
          const survey = this.state.survey;
          survey.questions = survey.questions.filter(q => q.questionText !== receivedQuestion.questionText);
          survey.questions.push(receivedQuestion);
          this.setState({ survey });
        }
      });
      this.state.hubConnection.on('renameQuestion', (id, oldQuestionText, newQuestionText) => {
        if (id == this.state?.survey?.id) {
          const survey = this.state.survey;
          survey.questions = this.state.survey.questions.map(q => {
            if (q.questionText == oldQuestionText){
              return {...q, questionText: newQuestionText, translations: null}
            } else if (q.parent == oldQuestionText) {
              return {...q, parent: newQuestionText}
            } else {
              return q;
            }
          });
          const comments = this.state.comments.map(c => {
            if (c.location == oldQuestionText){
              return {...c, location: newQuestionText}
            } else {
              return c;
            }
          })
          this.setState({ survey, comments });
        }
      });
      this.state.hubConnection.on('changeStatus', (id, status) => {
        if (id == this.state?.room?.id) {
          const room = this.state.room;
          room.status = status;
          this.setState({ room }, () => {
            if (this.state.room.status == "ranking") {
              this.populateResponseData();
            }
          });
        }
      });
      this.state.hubConnection.on('changeOpenStatus', (id, isOpen) => {
        if (id == this.state?.room?.id) {
          const room = this.state.room;
          room.isOpen = isOpen;
          this.setState({ room }, () => {
            console.log(this.state.room)
          });
        }
      });
      this.state.hubConnection.on('modifyRoom', (id, room) => {
        if (id == this.state?.room?.id) {
          this.setState(prevState => ({ room, viewTreeUser: (room.treeMode == 'show' && true) || (room.treeMode == 'hide' && false) || prevState.viewTreeUser, revealAnswer: false }), () => {
            this.populateQuizQuestion();
          });
        }
      });
      this.state.hubConnection.on('changeVideoOpenStatus', (id, isOpen) => {
        if (id == this.state?.room?.id) {
          this.setState({ videoClipOpen: isOpen });
        }
      });
      this.state.hubConnection.on('changeTreeMode', (id, mode) => {
        if (id == this.state?.room?.id) {
          this.setState({ room: {...this.state.room, treeMode: mode} });
        }
      });
      this.state.hubConnection.on('updateAssignedMods', (roomId, assignedMods) => {
        if (roomId == this.state?.room?.id) {
          const room = this.state.room;
          room.assignedMods = assignedMods;
          this.setState({ room });
        }
      });
      this.state.hubConnection.on('updateViewList', (roomId, visibleUsers) => {
        if (roomId == this.state?.room?.id) {
          const room = this.state.room;
          room.visibleUsers = visibleUsers;
          this.setState({ room });
        }
      });
      this.state.hubConnection.on('changeViewRoot', (id, viewRoot) => {
        if (id == this.state?.room?.id) {
          const room = this.state.room;
          room.viewRoot = viewRoot;
          this.setState({ room }, () => this.forceUpdate());
        }
      });
      this.state.hubConnection.on('moveRoom', (roomId, userOverride) => {
        if (userOverride && this.state.user?.redirectOverride) {
          window.top.location = this.state.user.redirectOverride;
        }
        else {
          let redirect = `/sharedtree/${roomId}`
          // if (window.top.location != window.self.location) {
            window.top.location = redirect;
          // }
          // this.setState({ redirect });
        }
      });
      this.state.hubConnection.on('redirectTo', (roomId, url) => {
        if (roomId == this.state?.room?.id) {
            setTimeout(() => {
              window.top.location = url;
            }, Math.random()*20000);
        }
      });
      this.state.hubConnection.on('userList', (userList) => {
        this.setState({ userList });
        console.log(userList);
      });
      this.state.hubConnection.on('userUpdate', (user) => {
        this.setState({ user });
        if (user.rank == "mod") {
          this.state.hubConnection
          .invoke("confirmInModGroup");
        }
      });
      this.state.hubConnection.on('refreshPage', () => {
        window.location.reload();
      });
      this.state.hubConnection.on('surveyUpdate', (id, survey) => {
        if (id == this.state?.room?.id) {
          this.setState({ survey });
        }
      });
      this.state.hubConnection.on('newComment', (docId, comment) => {
        if (docId == this.state?.survey?.id) {
          let comments = this.state.comments;
          comments.push(comment);
          this.setState({comments});
        }
      });
      this.state.hubConnection.on('updateComment', (comment) => {
        if (comment.document == this.state?.survey?.id) {
          // Grab the comment list and update the existing one
          let comments = this.state.comments.map((c) => {
            return (c.id == comment.id
              ? comment
              : c)
          });
          this.setState({comments});
        }
      });
      this.state.hubConnection.on('deleteComment', (id) => {
        console.log(this.state.comments);
        let comments = this.state.comments;
        comments = comments.filter(c => c.id !== id);
        console.log(comments)
        this.setState({ comments }, () => console.log(this.state.comments));
      });
      this.state.hubConnection.on('videoClip', (id, status) => {
        if (id == this.state?.room?.id) {
          this.setState({ videoClip: status == true });
        }
      });
      this.state.hubConnection.on('updateQuizQuestion', (updatedQuestion) => {
        if (this.state?.activeQuizQuestion?.id == updatedQuestion.id) {
          this.setState({activeQuizQuestion: updatedQuestion});
        }
      });
      this.state.hubConnection.on('revealAnswer', (roomId) => {
        if (roomId == this.state?.room?.id) {
          this.setState({revealAnswer: true});
        }
      });
    });
  } 

  allocateMeIfRequired() {
    if (this.state?.room?.status == "hub" && this.state.room.onAssignment && this.state.hubConnection?.state == HubConnectionState.Connected) {
      this.state?.user && this.state.hubConnection.invoke("allocateMe", this.state.room.event);
    } 
  }

  clearErrors() {
    this.setState({errors: []});
  }

  addError(e) {
    this.setState({errors: [...this.state.errors, e]})
  }

  ensureSubscribed(group) {
    this.state.hubConnection.invoke('subscribe', group);
  }

  readOnly() {
    return this.state?.room?.readOnlyMode == true || !this.state?.user
  }

  treeMode() {
    if (this.state?.treeOnlyParam) return 'treeOnly';
    return this.state?.room?.treeMode || 'show';
  }

  isMod() {
    return (this.state?.user?.rank == "mod" || this.state?.room?.assignedMods?.includes(this.state?.user?.username));
  }


  handleNewItem(event) {
    event.preventDefault();
    console.log(this.state.newItemUnder);
    console.log(this.state.newItemContent);
    if (!this.state.newItemContent) return false;
    if (this.state.newItemUnder == -1) return false;
    if (this.state.survey.questions.filter(q => q.questionText == this.state.newItemContent).length) return false;
    const newItem = {
      parent: this.state.newItemUnder,
      questionText: `${this.state.newItemContent}`,
      type: this.state.newItemType || this.state.room.status,
      language: this.state?.language || 'en',
      sortPriority: (Math.min(...this.state.survey.questions.filter(q=>q.parent == this.state.newItemUnder)?.map(q=>q.sortPriority), 0) - 1e6)
    }
    this.setState({newItemUnder: -1, newItemContent: "", newItemType: null});
    this.state.hubConnection
    .invoke('newQuestion', this.state.survey.id, newItem)
    .catch(err => console.error(err));
  }

  handleInputChange(event) {
    this.setState({newItemContent: event.target.value});
  }
  handleRenameChange(event) {
    event.currentTarget.style.height = "";event.currentTarget.style.height = (event.currentTarget.scrollHeight+3) + "px"
    this.setState({editModeRename: event.target.value.replaceAll('\n',' ')});
  }
  
  switchModes(event) {
    event.preventDefault();
    this.state.hubConnection
    .invoke('changeStatus', this.state.room.id, event.currentTarget.dataset.mode)
  }

  handleTwistyClick(e, showOverride, questionText) {
    const survey = this.state.survey;
    const thisQuestionText = questionText || e?.currentTarget.dataset.question;
    const open = showOverride || e.currentTarget.dataset.open == "true";
    survey.questions = survey.questions.map(q => {
      if (q.questionText == thisQuestionText) {
        return {...q, twistedOpen: open}
      } else {
        return q;
      }
    });
    this.setState({ survey, linkHighlight: null });
  }

  handleLinkToQuestion(questionText) {
    let openers = [];
    let nextQuestion = questionText;
    while (nextQuestion != null) {
      openers.push(nextQuestion);
      nextQuestion = this.state?.survey?.questions?.find(q => q.questionText == nextQuestion)?.parent;
    }
    const survey = this.state.survey;
    console.log(openers)
    survey.questions = survey.questions.map(q => {
      if (openers.includes(q.questionText)) {
        
        console.log(q.questionText);
        return {...q, twistedOpen: true}
      } else {
        return q;
      }
    });
    console.log(survey);
    console.log(questionText);
    let viewRootOverride = (this.state.room?.viewRoot && !openers.includes(this.state.room.viewRoot))
    this.setState({survey, linkHighlight: questionText, viewRootOverride}, () => {
      setTimeout(() => {
        document.getElementById('linked').scrollIntoView(true);
      }, 100);
    })
    
  }

  handleSelectItem(event, _dataset) {
    const dataset = event?.currentTarget?.dataset || _dataset;
    console.log(dataset);
    if (!this.state?.user) {
      this.setState({
        showGuestModal: true,
        onGuestModalLogin: () => {
          this.handleSelectItem(null, dataset);
        }
      });
      return;
    }
    if (dataset.question === this.state.newItemUnder) {
      this.setState({
        newItemUnder: -1,
        newItemContent: "",
        editModeItem: null,
        cutting: null,
        viewingComments: null,
        newCommentContent: "",
      });
    } else {
      this.state.room?.enableTwisties && this.handleTwistyClick(null, true, dataset.question);
      let newItemContent = "";
      if (dataset.nodetype == 'amendment') {
        newItemContent = dataset.question;
      }
      this.setState({
        newItemUnder: dataset.question || null,
        newItemContent,
        editModeItem: null,
        newItemType: this.state.room.status == "document"? dataset.nodetype : null,
        cutting: null,
        viewingComments: null,
        newCommentContent: "",
      });
    }
  }

  handleSelectEditModeItem(event) {
    var question = this.state.survey.questions.find(q => q.questionText == event.currentTarget.dataset.question);
    console.log(question);

    if (this.isMod() || (question.author != null && question.author == this.state.user?.username && (!this.readOnly() || question.type=='suggestion')))
      this.setState({
        editModeItem: event.currentTarget.dataset.question || null,
        newItemUnder: -1,
        cutting: null,
        viewingComments: null,
        newCommentContent: "",
        editModeRename: question.questionText
      });
  }

  handleCutItem(event) {
    this.setState({
      cutting: event.currentTarget.dataset.question,
      viewingComments: null,
      newCommentContent: "",
    });
  }

  handleViewComments(event) {
    if (event.currentTarget.dataset.question === this.state.viewingComments) {
      this.setState({
        newItemUnder: -1,
        newItemContent: "",
        editModeItem: null,
        cutting: null,
        viewingComments: null,
        newCommentContent: "",
      });
    } else {
      this.setState({
        newItemUnder: -1,
        editModeItem: null,
        cutting: null,
        viewingComments:  event.currentTarget.dataset.question
      });
    }
  }

  handleVote(e) {
    const myComment = this.state.comments.find(c =>
      c.author == this.state.user.username && c.location == e.currentTarget.dataset.question && c.content.startsWith('_'));
    e.preventDefault();
    if (myComment) {
      this.state.hubConnection
    .invoke('deleteComment', this.state.room?.id, myComment.id)
    }
    if (!myComment || myComment.content !== e.currentTarget.dataset.vote) {
      this.state.hubConnection
      .invoke('newComment', this.state.survey.id, {
        location: e.currentTarget.dataset.question,
        content: e.currentTarget.dataset.vote
      });
    }

  }

  handlePasteItem(event) {
    event.preventDefault();
    const modifyItem = {
      questionText: this.state.cutting
    };
    modifyItem.parent = event.currentTarget.dataset.parent || null;
    if (event.currentTarget.dataset.above) {
      console.log("pasting above");
      let aboveSortPri = this.state.survey.questions.find(q => q.questionText == event.currentTarget.dataset.above).sortPriority;
      let nextSortPri = Math.min(...this.state.survey.questions.filter(q=>q.parent == modifyItem.parent && q.sortPriority > aboveSortPri)?.map(q=>q.sortPriority), aboveSortPri + 1e6);
      
      console.log(aboveSortPri);
      
      console.log(nextSortPri);
      modifyItem.sortPriority = (aboveSortPri+nextSortPri)/2;
    } else if (event.currentTarget.dataset.below) {
      let belowSortPri = this.state.survey.questions.find(q => q.questionText == event.currentTarget.dataset.below).sortPriority;
      let nextSortPri = Math.max(...this.state.survey.questions.filter(q=>q.parent == modifyItem.parent && q.sortPriority < belowSortPri)?.map(q=>q.sortPriority), belowSortPri - 1e6);
      modifyItem.sortPriority = (belowSortPri+nextSortPri)/2;
    } else {
      modifyItem.sortPriority = (Math.min(...this.state.survey.questions.filter(q=>q.parent == modifyItem.parent)?.map(q=>q.sortPriority), 0) - 1e6)
    }
    console.log(modifyItem);
    this.state.hubConnection
    .invoke('modifyQuestion', this.state.survey.id, this.state.room?.id, modifyItem);
    this.setState({
      cutting: null
    })
    if(this.state.editModeRename == this.state.editModeItem) {
      this.setState({
        editModeItem: null
      })
    }
  }

  handleDeleteItem(event) {
    event.preventDefault();
    const deleteItem = {
      questionText: event.currentTarget.dataset.question
    };

    this.state.hubConnection
    .invoke('deleteQuestion', this.state.survey.id, this.state.room?.id, deleteItem);
  }

  getQuestionAncestors(questionText) {
    let question = this.state.survey.questions
    ?.find(q => q.questionText == questionText);
    if (!question.parent) return [];
    return [...this.getQuestionAncestors(question.parent), question.parent];
  }

  handleModifyItem(event) {
    event.preventDefault();
    const modifyItem = {
      questionText: event.currentTarget.dataset.question,
      parent: "UNCHANGED"
    };
    if (event.currentTarget.dataset.nodetype) {
      modifyItem.type = event.currentTarget.dataset.nodetype;
    }
    

    this.state.hubConnection
    .invoke('modifyQuestion', this.state.survey.id, this.state.room?.id, modifyItem);
  }

  handleRankUser(event) {
    event.preventDefault();
    this.state.hubConnection
    .invoke('rankUser', event.currentTarget.dataset.user, event.currentTarget.dataset.rank);
  }

  handleRefreshUser(event) {
    event.preventDefault();
    this.state.hubConnection
    .invoke('refreshUser', event.currentTarget.dataset.user);
  }

  handleUserVisible(event) {
    event.preventDefault();
    console.log(event.currentTarget.dataset.user);
    console.log(event.currentTarget.dataset.show);
    this.state.hubConnection
    .invoke('userVisible',
      this.state.room.id,
      event.currentTarget.dataset.user,
      event.currentTarget.dataset.show === "true");
  }

  handleUpdateViewRoot(event) {
    event.preventDefault();
    this.setState({viewRootOverride: false});
    this.state.hubConnection
    .invoke('changeViewRoot', this.state.room.id, event.currentTarget.dataset.root)
  }
  handleRenameQuestion(event) {
    event.preventDefault();


    if (this.state.editModeRename && this.state.editModeRename.length
      && !(this.state.editModeRename != this.state.editModeItem && this.state.survey.questions?.find(q => q.questionText == this.state.editModeRename))) {
      this.state.hubConnection
      .invoke('renameQuestion', this.state.survey.id, this.state.editModeItem, this.state.editModeRename);
      this.setState({
        newItemUnder: -1,
        newItemContent: "",
        editModeItem: null,
        cutting: null,
        viewingComments: null,
        newCommentContent: "",
      });
    }

  }
  
  handleAllocateRooms(event) {
    event.preventDefault();
    this.state.hubConnection
    .invoke('allocateToGroups', this.state.room.event);
  }

  handlePullToHub(event) {
    event.preventDefault();
    this.state.hubConnection
    .invoke('returnToHub', this.state.room.event);
  }
 
  handleSliderChange(questionText, value) {
    let response = this.state.response;
    response.questionResponses.find(q => q.questionText == questionText).response = value.toString();
    this.setState({ response }, async () => {
      const fetchResponse = await fetch(`/api/surveyresponse/${response.id}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(response)
      })
    })
    
    this.forceUpdate();
  }
  
  handleSlideStop(questionText, value) {
    console.log(`${questionText}: ${value}`);
  }

  handleRoomOpenStatus(event) {
    console.log(event.currentTarget.dataset.open);
    this.state.hubConnection
    .invoke('changeOpenStatus', this.state.room.id, (event.currentTarget.dataset.open == 'true'))
  }

  handleVideoClipStatus(event) {
    console.log(event.currentTarget.dataset.open);
    this.state.hubConnection
    .invoke('changeVideoOpenStatus', this.state.room.id, (event.currentTarget.dataset.open == 'true'))
  }

  handleVideoClipClose(event) {
    this.setState({ videoClipOpen: false });
  }

  handleChangeTreeMode(event) {
    this.state.hubConnection
    .invoke('changeTreeMode', this.state.room.id, (event.currentTarget.dataset.mode))
  }

  handleRedirectAll(event) {
    console.log(event.currentTarget.dataset.open);
    this.state.hubConnection
    .invoke('RedirectAll', this.state.room.id, '/matcher/61099db59a86841439a48c58')
  }

  handleOpenTranslateModal(event, questionTextOverride) {
    let questionText = questionTextOverride || event.currentTarget.dataset.question;
    if (!this.state?.user) {
      this.setState({
        showGuestModal: true,
        onGuestModalLogin: () => {
          this.handleOpenTranslateModal(null, questionText);
        }
      });
      return;
    }
    this.setState({openTranslationModal: this.state?.survey?.questions?.find(q => q.questionText == questionText)});
  }

  handleCloseTranslateModal(translatedText) {
    if (translatedText) {
      const translation = {
        translatedText,
        language: (this.state.language || 'en')
      };
      this.state.hubConnection
      .invoke('newTranslation', this.state?.survey?.id, this.state.openTranslationModal.questionText, translation);
      // this.setState(prevState => ({
      //   ...prevState,
      //   openTranslationModal: null,
      //   survey: {
      //     ...prevState.survey,
      //     questions: prevState.survey.questions.map(q => {
      //       if (q.questionText == prevState.openTranslationModal){
      //         return ({
      //           ...q,
      //           translations: q.translations.map(tr => {
      //             if (tr.language == prevState.language) {
      //               return ({
      //                 ...tr,
      //                 translatedText: translatedText
      //               })
      //             } else {
      //               return tr;
      //             }
      //           })
      //         })
      //       } else {
      //         return q;
      //       }
      //     })
      //   }
      // }));
    }
    // else {
    //   this.setState({openTranslationModal: null})
    // }
    this.setState({openTranslationModal: null})
  }

  // reSortItems() {

  //   let votes = ((b.upvotes?.length || 0) - (b.downvotes?.length || 0)) - ((a.upvotes?.length || 0) - (a.downvotes?.length || 0))
  //   if (votes != 0) return votes;
  // }

  renderQuestions(parentQuestion, renderParent) {
    let elements = [];
    if (!this.state.survey) return null;
    this.state.survey.questions
    .filter(q => (renderParent? q.questionText == parentQuestion : q.parent == parentQuestion))
    .map(q => {
      if (['document','vanilla'].includes(this.state.room.status) || q.type=='suggestion') {
        
      q.upvotes = this.state.comments?.filter(c => (c.location == q.questionText && c.content == '_upvote'));
      q.downvotes = this.state.comments?.filter(c => (c.location == q.questionText && c.content == '_downvote'));
      }
      return q
    })
    .sort((a,b) => {
      if (this.state.room.status == 'document') {
        const typeOrder = ['document', 'amendment','solution','issue','suggestion'];
        const typeOrderScore = typeOrder.indexOf(a.type) - typeOrder.indexOf(b.type);
        if (typeOrderScore != 0) return typeOrderScore;
      }

      // Sort by score
      if (b.sortPriority > a.sortPriority) return 1;
      if (b.sortPriority < a.sortPriority) return -1;
      
      if (b.created < a.created) return 1;
      if (b.created > a.created) return -1;

      return b.questionText < a.questionText ? 1 : -1
    })
    .map(q => {
      let amendment = null;
      if (q.type === 'amendment' && q.parent) {
        const diff = new Diff();
        const textDiff = diff.main(q.parent, q.questionText);
        diff.cleanupSemantic(textDiff);
        amendment = <span dangerouslySetInnerHTML={{__html: diff.prettyHtml(textDiff)}}/>;
        console.log(amendment);
      }
      let qr = this.state.response?.questionResponses?
        this.state.response.questionResponses.find(thisqr => thisqr.questionText == q.questionText)
        :null;
      // Checks before rendering this item, currently disabled as suggestions were the only thing filtered out and now they're visible.
      if (true /* q.type != 'suggestion' || q.author == this.state?.user?.username || this.isMod() */) {
        let childElements = this.renderQuestions(q.questionText);
        let itemComments = this.state.comments?.filter(c => (c.location == q.questionText && !c.content.startsWith('_')));
        // If the user wants a translation, and there is one, put it here, else null
        let thisTranslation = ((this.state?.language || 'en') != (q.language || 'en') && q.translations?.find(tr => tr.language == (this.state?.language || 'en'))) || null;
        elements.push(<div className="question-box mb-2" id={q.questionText == this.state?.linkHighlight? "linked" : null}>
          {this.state.editModeItem == q.questionText && 
            <form className="editItemForm" onSubmit={this.handleRenameQuestion}>
              <div className="form-group rename-input">
                <div className="input-group  mt-2">

                  {this.state.newItemMaxLength && this.state.newItemMaxLength < 100 &&
                    <input
                      maxLength={this.state.newItemMaxLength}
                      autoFocus value={this.state.editModeRename}
                      onChange={this.handleRenameChange}
                      type="text"
                      id="rename-item-input"
                      className="form-control"
                    />
                  ||
                    <textarea
                      maxLength={this.state.newItemMaxLength}
                      autoFocus value={this.state.editModeRename}
                      onChange={this.handleRenameChange}
                      type="text"
                      id="rename-item-input"
                      className="form-control"
                    />
                  }
                  <div className="input-group-append">
                    <button type="submit" className={`btn btn-primary btn-${q.type}`}>
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                  </div>
                </div>
                {this.state.newItemContent.length == this.state.newItemMaxLength?
                  <small className="form-text text-muted">Maximum text length reached. </small>
                : null}
                {this.state.editModeRename != this.state.editModeItem && this.state.survey.questions.find(q => q.questionText == this.state.editModeRename)?
                  <small className="form-text text-muted">This is a duplicate of an existing item. </small>
                : null}
                {this.state.editModeRename == "" ?
                  <small className="form-text text-muted">Please enter some content to make a change, or press delete below. </small>
                : null}
              </div>
            </form>
          ||
            <>
              {this.state.room.enableTwisties &&
                <span
                  className="mr-2 cocreate-button"
                  onClick={this.handleTwistyClick}
                  data-question={q.questionText}
                  data-open={!q.twistedOpen}
                >
                  <FontAwesomeIcon
                    icon={faChevronCircleRight}
                    transform={{rotate: q.twistedOpen? 90 : 0}}
                    color="#666"
                    visibility={childElements? "visible" : "hidden"}
                  />
                </span>
              }
              <span
                className={`${q.type} ${(this.isMod() || (q.author != null && q.author == this.state.user?.username && (!this.readOnly() || q.type == 'suggestion'))) && "editModeSelect"}`}
                onClick={this.handleSelectEditModeItem}
                data-question={q.questionText}
                data-tip={
                  // If not showing translation, and the text has changed from the original
                  (q.originalText != null && (q.originalText != q.questionText) && !thisTranslation? `Original: "${q.originalText}". `: "")
                  // If showing a translation
                  + (thisTranslation? `${langName(q.language)}: "${q.questionText}". `: "")
                  // If not showing a translation
                  + ((q.author && q.author != 'andeye') && !thisTranslation ?` Posted by ${q.author}.`:"")
                  // If showing a translation with an author
                  + (thisTranslation?.author? ` Translation by ${thisTranslation.author}`:"")
                  || null}
              >
                  {amendment ||
                  (q.displayAsMarkdown && ['[','*','_'].some(char => q.questionText.includes(char))? // Temp fix to prevent 
                    <ReactMarkdown
                      allowedElements={['a', 'em', 'strong']}
                      components={{a: ({node, ...props}) => <TreeLink handleLinkToQuestion={this.handleLinkToQuestion} {...props}/>}}
                      linkTarget="_blank"
                      unwrapDisallowed={true}
                    >
                      {q.questionText}
                    </ReactMarkdown>
                  
                  :
                    (thisTranslation?.translatedText || q.questionText)
                  )
                  }
                  {
                    //Condition for displaying authorship
                    (this.state?.room?.status == 'document' && ['issue', 'solution'].includes(q.type) && 
                      <small>{' — '}{q.author}</small>
                    )

                  }
              </span>
            </>
          }
          <div className="d-inline-block">        
            {/* "Add" button for if issue/solution/vanilla mode is picked and we're not in read-only, or suggestion mode is picked*/}
            {
              (
                (
                  (
                    ['issue', 'solution', 'vanilla'].includes(this.state.room.status)
                  &&
                    (!this.readOnly() || this.isMod())
                  )
                ||
                  (this.state.room.status == 'suggestion')
                )
              &&
                (!this.state.room.enableTwisties || q.twistedOpen || !childElements)
              )
              ?
            <span data-tip={`Add ${this.state.room.status}`} className="cocreate-button outline-button ml-2" onClick={this.handleSelectItem} data-question={q.questionText}>
              <FontAwesomeIcon icon={faPlus} transform={this.state.newItemUnder == q.questionText && {rotate:45}} className={`icon-${this.state.room.status}`}/>
            </span>

            :null}

            {thisTranslation?
            <span
              data-tip="Correct this translation"
              className="cocreate-button ml-2"
              onClick={this.handleOpenTranslateModal}
              data-question={q.questionText}
            >
              <FontAwesomeIcon icon={faLanguage}  color='#999'/>
            </span>
            :null}

            {/* "Add" button for pros in document mode if not read-only */}
            {(this.state.room.status == 'document' && q.type == 'document' && (!this.readOnly() || this.isMod()))?
            <span data-tip="Add a pro" className="cocreate-button outline-button ml-2" onClick={this.handleSelectItem} data-question={q.questionText} data-nodetype='solution'>
              <FontAwesomeIcon icon={faPlus}  color='#6f6'/>
            </span>
            :null}

            {/* "Add" button for cons in document mode */}
            {(this.state.room.status == 'document' && q.type == 'document' && (!this.readOnly() || this.isMod()))?
            <span data-tip="Add a con" className="cocreate-button outline-button ml-2" onClick={this.handleSelectItem} data-question={q.questionText} data-nodetype='issue'>
              <FontAwesomeIcon icon={faMinus} color='#f66'/>
            </span>
            :null}
            
            {(this.state.room.status == 'document' && ['document', 'amendment'].includes(q.type) && (!this.readOnly() || this.isMod()))?
            <span data-tip="Propose an amendment" className="cocreate-button outline-button ml-2" onClick={this.handleSelectItem} data-question={q.questionText} data-nodetype='amendment'>
              <FontAwesomeIcon icon={faExchangeAlt} color='#88f'/>
            </span>
            :null}

            {((this.state.room.status == 'document' && ['document', 'amendment', 'issue', 'solution'].includes(q.type))
              ||
              (this.state.room.status == 'suggestion' && q.type == 'suggestion')
              ||
              (this.state.room.status == 'vanilla')) &&
              this.state.user &&
              this.state?.hubConnection?.state == "Connected" &&
            <>
              <span className={`cocreate-button ml-2 ${q.upvotes?.length? ' mr-2 position-relative' : null}`} onClick={this.handleVote} data-question={q.questionText} data-vote="_upvote">
                <FontAwesomeIcon icon={q.upvotes?.find(c => c.author == this.state.user.username) && faSolidThumbsUp || faRegularThumbsUp} color="#888"/>
                {q.upvotes?.length? <span className="comment-count">{q.upvotes.length}</span> : null}
              </span>
              <span className={`cocreate-button ml-2 ${q.downvotes?.length? ' mr-2 position-relative' : null}`} onClick={this.handleVote} data-question={q.questionText} data-vote="_downvote">
                <FontAwesomeIcon rotation={180} icon={q.downvotes?.find(c => c.author == this.state.user.username) && faSolidThumbsUp || faRegularThumbsUp} color="#888"/>
                {q.downvotes?.length? <span className="comment-count">{q.downvotes.length}</span> : null}
              </span>
            </>
            }
            
            {!['document', 'vanilla'].includes(this.state.room.status) && (!this.readOnly() || itemComments?.length || this.isMod() || (q.author && q.author == this.state?.user?.username)) &&
            <span data-tip={itemComments?.length? "Add/view comments" : "Add a comment"} className={`cocreate-button outline-button outline-grey ml-2 ${itemComments?.length? ' mr-2 position-relative' : null}`} onClick={this.handleViewComments} data-question={q.questionText}>
              <FontAwesomeIcon icon={faCommentDots} color="#f3f381"/>
              {itemComments?.length? <span className="comment-count">{itemComments.length}</span> : null}
            </span>
            }

            {(q.questionText != this.state.room.viewRoot && this.isMod())?
            <span data-tip="Focus everyone on this item" className="cocreate-button ml-2" onClick={this.handleUpdateViewRoot} data-root={q.questionText}>
              <FontAwesomeIcon icon={faBullseye} />
            </span>
            : null}
            
            {(((q.questionText == this.state.room.viewRoot)
            ) && this.isMod())?
            <span data-tip="Take everyone up one level" className="cocreate-button ml-2" onClick={this.handleUpdateViewRoot} data-root={q.parent}>
              <FontAwesomeIcon icon={faLevelUpAlt} flip="horizontal" />
            </span>
            : null}

            {this.state.editModeItem === q.questionText &&
              <>
                {(q.type != "issue") && this.isMod()?
                <span data-tip="Convert to an issue" className="cocreate-button ml-2 outline-button" onClick={this.handleModifyItem} data-question={q.questionText} data-nodetype="issue">
                  <FontAwesomeIcon icon={faExclamationCircle} color="#f66"/>
                </span>
                : null}

                {(q.type != "solution") && this.isMod()?
                <span data-tip="Convert to a solution" className="cocreate-button ml-2 outline-button" onClick={this.handleModifyItem} data-question={q.questionText} data-nodetype="solution">
                  <FontAwesomeIcon icon={faCheckCircle} color="#6f6"/>
                </span>
                : null}

                {(q.type != "category") && this.state?.room?.status != "document" && this.isMod()?
                <span data-tip="Convert to a category" className="cocreate-button ml-2" onClick={this.handleModifyItem} data-question={q.questionText} data-nodetype="category">
                  <FontAwesomeIcon icon={faLayerGroup} />
                </span>
                : null}

                {(q.type != "document") && this.state?.room?.status == "document" && this.isMod()?
                <span data-tip="Convert to an original text item" className="cocreate-button ml-2" onClick={this.handleModifyItem} data-question={q.questionText} data-nodetype="document">
                  <FontAwesomeIcon icon={faLayerGroup} />
                </span>
                : null}

                {(q.questionText != this.state.room.viewRoot && (!childElements && ((q.author && q.author == this.state?.user?.username && (!this.readOnly() || q.type == 'suggestion')) || this.isMod())))?
                <span data-tip="Delete this item" className="cocreate-button ml-2" onClick={this.handleDeleteItem} data-question={q.questionText}>
                  <FontAwesomeIcon icon={faTrashAlt} />
                </span>
                : null}

                {(!(this.state.room.viewRoot == q.questionText) && (q.parent || this.state.survey.questions.filter(q=> q.parent == null).length > 1) && (!this.state.cutting || this.state.cutting == q.questionText) && this.isMod())?
                <span data-tip="Cut this item (to paste elsewhere)" className="cocreate-button ml-2" onClick={this.handleCutItem} data-question={q.questionText}>
                  <FontAwesomeIcon icon={faCut} />
                </span>
                : null}


              </>
            }

            {(this.state.cutting && this.state.cutting != q.questionText && !this.getQuestionAncestors(q.questionText).includes(this.state.cutting) && this.isMod())?
            <>
              <span data-tip="Paste as a child of this item" className="cocreate-button ml-2 fa-layers fa-fw" onClick={this.handlePasteItem} data-parent={q.questionText}>
                <FontAwesomeIcon icon={faClipboard} />
                <FontAwesomeIcon icon={faLevelDownAlt} transform="shrink-7 down-3" color="#ccc"/>
              </span>
              {["issue","solution"].includes(this.state.room.status) &&
                <>
                  <span data-tip="Paste above this item" className="cocreate-button ml-2 fa-layers fa-fw" onClick={this.handlePasteItem} data-parent={q.parent} data-above={q.questionText}>
                    <FontAwesomeIcon icon={faClipboard} />
                    <FontAwesomeIcon icon={faCaretUp} transform="shrink-7 down-3" color="#ccc"/>
                  </span>
                  <span data-tip="Paste below this item" className="cocreate-button ml-2 fa-layers fa-fw" onClick={this.handlePasteItem} data-parent={q.parent} data-below={q.questionText}>
                    <FontAwesomeIcon icon={faClipboard} />
                    <FontAwesomeIcon icon={faCaretDown} transform="shrink-7 down-3" color="#ccc"/>
                  </span>
                </>
              }
            </>
            : null}

            {this.state.editModeItem === q.questionText && this.isMod() && q.author && <span data-tip="Author of this item" class="author ml-2">{q.author}</span>}
          </div>

          {this.state.room.status == "ranking" && ['issue','solution'].includes(q.type) && qr?
          <div className="ranking-slider-test mt-2 mb-3">
            <SliderQuestion
              onValueChange={this.handleSliderChange}
              onSlideStop={this.handleSlideStop}
              question={qr}
              showText={false}
              key={`slider ${q.questionText}`}
              />
            </div>
          : null}

          {this.state.viewingComments == q.questionText?
          <CommentViewer
            className="mt-3"
            comments={itemComments}
            language={this.state.language}
            hubConnection={this.state.hubConnection}
            docId={this.state.survey?.id}
            location={q.questionText}
            thisUser={this.state.user}
            handleLinkToQuestion={this.handleLinkToQuestion}
            canAddComments={!this.readOnly() || this.isMod() || (q.author && q.author == this.state?.user?.username)}
            roomID={this.state.room?.id}/>
          : null}
          
          
          
        </div>);
        if (childElements && (!this.state.room?.enableTwisties || q.twistedOpen)) {
          elements.push(childElements);
        }
        if (this.state.newItemUnder == q.questionText) {
          elements.push(
            <form className="newItemForm" onSubmit={this.handleNewItem}>
              <div className="form-group">
                <div className="input-group mt-2">
                  {this.state.newItemMaxLength && this.state.newItemMaxLength < 100 &&
                    <input
                      maxLength={this.state.newItemMaxLength}
                      autoFocus
                      value={this.state.newItemContent}
                      onChange={this.handleInputChange}
                      type="text"
                      id="new-item-input"
                      className="form-control"
                      placeholder={this.state?.room?.status == 'issue'? "Add an issue" : this.state?.room?.status == 'solution'? "Add a solution" : null}/>
                  ||
                    <textarea
                      maxLength={this.state.newItemMaxLength}
                      autoFocus
                      value={this.state.newItemContent}
                      onChange={this.handleInputChange}
                      onInput={e => {e.currentTarget.style.height = "";e.currentTarget.style.height = (e.currentTarget.scrollHeight+3) + "px"}}
                      type="text"
                      id="new-item-input"
                      className="form-control"
                    />
                  }

                  <div className="input-group-append">
                    <button type="submit" className={`btn btn-primary btn-${this.state.newItemType || this.state.room.status}`}>
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                  </div>
                </div>
                {this.state.newItemContent.length == this.state.newItemMaxLength?
                  <small className="form-text text-muted">Maximum text length reached</small>
                : null}
                {this.state.survey.questions.find(q => q.questionText == this.state.newItemContent)?
                  <small className="form-text text-muted">This is a duplicate of an existing item. </small>
                : null}
              </div>
            </form>
          )
        }
      }
    });
    if (parentQuestion == null && this.isMod()) {

      elements.push(
        this.state.newItemUnder == null? 
          <form onSubmit={this.handleNewItem}>
            <div className="form-group">
              <div className="input-group suggestion-input mt-2">

                <input maxLength={this.state.newItemMaxLength} autoFocus value={this.state.newItemContent} onChange={this.handleInputChange} type="text" id="new-item-input" className="form-control" placeholder={this.state?.room?.status == 'issue'? "Add an issue" : this.state?.room?.status == 'solution'? "Add a solution" : null}/>

                <div className="input-group-append">
                  <button type="submit" className="btn btn-primary">
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </button>
                </div>
              </div>
              {this.state.newItemContent.length == this.state.newItemMaxLength?
                <small className="form-text text-muted">Maximum text length reached</small>
              : null}
              {this.state.survey.questions.find(q => q.questionText == this.state.newItemContent)?
                <small className="form-text text-muted">This is a duplicate of an existing item. </small>
              : null}
            </div>
            
          </form>
        :
        <button data-tip="Creates another item at the top level" type="button" className="btn btn-secondary btn-sm" onClick={this.handleSelectItem} data-question={null}>
          <FontAwesomeIcon icon={faPlus}/> Add a root node
        </button>
      );
    }
    if (elements.filter(e => (e != null)).length) {
      return <div className="question-list">{elements}</div>
    }
  }

  async populateRoomData() {
    const response = await fetch('api/room/' + this.props.match.params.id);
    const data = await response.json();
    this.setState({room: data}, () => {
      if (this.state.room.status == 'document' || this.state.room.bypassLengthLimit) {
        this.setState({newItemMaxLength: null});
      }
      if (this.state.room.onAssignment) {
        this.allocateMeIfRequired();
      }
      else {
        this.populateSurveyData();
        this.populateQuizQuestion();
        this.forceUpdate();
      }
    })
  }

  async populateSurveyData() {
    const response = await fetch('api/survey/' + this.state.room.survey + (this.state?.language? "?lang="+this.state.language : ''));
    const data = await response.json();
    this.setState({survey: data}, () => {
      if (this.state.room.status == "ranking") {
        this.populateResponseData();
      }
      this.populateCommentData();
      this.forceUpdate();
    })
  }

  async populateCommentData() {
    const response = await fetch(`api/survey/${this.state.room.survey}/comment`+ (this.state?.language? "?lang="+this.state.language : ''));
    const comments = await response.json();
    this.setState({comments})
  }

  async populateUserData(cb) {
    const response = await fetch('api/user/');
    
    console.log(response);
    if (response.ok) {
      const data = await response.json();
      this.setState({user: data}, () => {
        
        this.forceUpdate();
        cb && cb();
      })
    } else {
      cb && cb()
    }
    
    //setTimeout(this.populateUserData, (Math.random() + 1) * 60000)
  }

  async populateQuizQuestion(cb) {
    if (this.state?.room?.activeQuizQuestion) {
      fetch(`/api/quizquestion/${this.state.room.activeQuizQuestion}`)
      .then(response => response.json())
      .then(activeQuizQuestion => this.setState({activeQuizQuestion}))
    } else if (this?.state?.room) {
      this.setState({activeQuizQuestion: null, revealAnswer: false});
    }
  }

  async populateResponseData() {
    
    const response = await fetch('api/surveyresponse', {
      method: "POST",
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({survey: this.state.survey.id})
    });
    const data = await response.json();
    if (data.questionResponses == null) {
      data.questionResponses = [];
    }
    if (this.state.survey?.questions) {
      this.state.survey.questions.forEach(q => {
        if (!data.questionResponses.find(qr => qr.questionText == q.questionText)) {
          data.questionResponses.push({
            questionText: q.questionText,
            type: "slider",
            response: "10"
          })
        }
      })
    }
    console.log(data);
    this.setState({ response: data });
  }



  render() {

    // if (this.state?.redirect) {
    //   console.log(this.state.redirect);
    //   let redirect = <Redirect push to={this.state.redirect} />;
    //   this.setState({redirect: null}, () => {
    //     //this.populateRoomData();
    //   });
    //   return this.state.redirect;
    // }
    if (this.state?.activeQuizQuestion && this.state.user) {
      return <QuestionPlayer key={this.state.activeQuizQuestion.id} question={this.state.activeQuizQuestion} username={this.state.user.username} revealAnswer={this.state?.revealAnswer}/>
    }
    if (this.state?.room?.videoClip && this.state?.videoClipOpen) {
      return <div>
        <VimeoPlayer options={{url: this.state.room?.videoClip, autoplay: true}} />
        <button className="btn btn-dark vimeo-embed-close" onClick={this.handleVideoClipClose}>X</button>
      </div>
    }
    let contents = null;
    if (this.state?.room?.status == "hub") {
      contents = <div>
        {this.isMod()?
          <div>
            <p>You are a moderator and can control this event</p>
            {this.state?.hubConnection?.state == "Connected"?
              <div>
                <p>
                  Push all event attendees to their favourite room:
                  <button type="submit" onClick={this.handleAllocateRooms} className="btn btn-primary" >Allocate users to rooms</button>
                </p>
                <p>
                  Pull all event attendees back to the event page from which they were taken:
                  <button type="submit" onClick={this.handlePullToHub} className="btn btn-primary" >Return users to event page</button>
                </p>
                {this.state.user.userID == "73295" /* TODO <--- this needs removing */ &&
                  <button data-tip="Redirect to matcher" type="submit" onClick={this.handleRedirectAll} className="btn btn-secondary mr-1 mb-1">RedirectAll</button>
                }
              </div>
            :null}
          </div>
        :
          <p>This page is listening for andeye Interactive control messages.</p>
        }
      </div>
    }
    else {
      let intro = null;
      if (this.state?.room?.header) {
        intro = <ReactMarkdown
        linkTarget="_blank"
      >
        {this.state.room.header}
      </ReactMarkdown>
      } else if (this?.state?.survey) {
        let introText = null;
        if (this.state.room.status == 'issue') {
          introText = <span className="outline-button">
            We are in issues mode. Review the current issues (shown in red) and add more by pressing the <FontAwesomeIcon icon={faPlus} className="icon-issue"/> icon.
          </span>
        } else if (this.state.room.status == 'solution') {
          introText = <span className="outline-button">
            We are in solutions mode. Review the current issues (shown in red) and solutions (shown in green), and add more solutions by pressing the <FontAwesomeIcon icon={faPlus} className="icon-solution"/> icon.
          </span>
        } else if (this.state.room.status == 'ranking') {
          introText = <span className="outline-button">
            We are in ranking mode. Move the sliders to the right for important items, or to the left for less important items.
          </span>
        } /* else if (this.state.room.status == 'suggestion') {
          introText = <>
            <h3>
              Water Climate Issues Hierarchy
            </h3>
          </>
        }*/
        intro = <div className="my-3">
          <p>{introText}</p>
          {!this.state.room?.vimeo && this.treeMode() != 'treeOnly' && <p>If you cannot see a video call on the right of your screen, visit our <a href="https://wcd.andeye.com/help">help page</a>, where you can find potential solutions or live support from our team.</p>}
        </div>
      } 
      let modTools = null;
      if (this.isMod() && this.state?.room && this.state?.hubConnection?.state == "Connected") {
        modTools = <div className="my-3">
          <span>
            {(["solution","ranking"].includes(this.state.room.status))?
            <button type="submit" onClick={this.switchModes} className="btn btn-primary mr-1 mb-1" data-mode="issue">'Issue' mode{this.state.room.status == "ranking"? " (and re-sort)":null}</button>
            : null}
            {(["issue","ranking"].includes(this.state.room.status))?
            <button type="submit" onClick={this.switchModes} className="btn btn-primary mr-1 mb-1" data-mode="solution">'Solution' mode{this.state.room.status == "ranking"? " (and re-sort)":null}</button>
            : null}
            {(["issue","solution"].includes(this.state.room.status)) && !this.state.room.disableRankingMode?
            <button type="submit" onClick={this.switchModes} className="btn btn-primary mr-1 mb-1" data-mode="ranking">'Ranking' mode</button>
            : null}
            <button data-tip="Return all users to the top of this tree" type="submit" onClick={this.handleUpdateViewRoot} className="btn btn-secondary mr-1 mb-1" data-root={null}>Reset root</button>
            {this.state.user.userID == "73295" /* TODO <--- this needs removing */ && this.state.room.assignmentSurvey && ((this.state.room.isOpen)?
            <button data-tip="Prevent new users from entering this room" type="submit" onClick={this.handleRoomOpenStatus} className="btn btn-secondary mr-1 mb-1" data-open={false}>Close room</button>
            : 
            <button data-tip="Allow new users to be allocated to this room" type="submit" onClick={this.handleRoomOpenStatus} className="btn btn-secondary mr-1 mb-1" data-open={true}>Open room</button>
            )}
            {this.state.user.userID == "73295" /* TODO <--- this needs removing */ &&
            <button data-tip="Redirect to matcher" type="submit" onClick={this.handleRedirectAll} className="btn btn-secondary mr-1 mb-1">RedirectAll</button>
            }
            {!this.state.room?.vimeo && this.treeMode() != 'treeOnly' && (
              this.state.room?.visibleUsers === null ?
                <button data-tip="Remove all other videos from the call" type="button" onClick={this.handleUserVisible} className="btn btn-secondary mr-1 mb-1" data-username={null} data-show={false}>
                  <FontAwesomeIcon icon={faVideoSlash} /> Hide all video (except me)
                </button>
              :
                <button data-tip="Allow all other video in the call" type="button" onClick={this.handleUserVisible} className="btn btn-secondary mr-1 mb-1" data-username={null} data-show={true}>
                  <FontAwesomeIcon icon={faVideo} /> Show all video
                </button>
            )}
            {this.state.room.videoClip && 
              <button data-tip="Play the preselected outro video to all" type="submit" onClick={this.handleVideoClipStatus} className="btn btn-secondary mr-1 mb-1" data-open={true}>
                <FontAwesomeIcon icon={faPlay} /> Play Outro
              </button>
            }

            {['show','user'].includes(this.treeMode()) && ["73295", "113624"].includes(this.state.user?.userID) &&
              <button data-tip="Hide the tree" type="submit" onClick={this.handleChangeTreeMode} className="btn btn-secondary mr-1 mb-1" data-mode="hide">
                Hide tree for all
              </button>
            }
          </span>
          {this.state.user?.rank == "mod" && <div class="user-list card mt-2">
            <div className="card-body p-2">
            <h5 className="card-title mb-0"
              onClick={(e) => this.setState({userListOpen: !this.state.userListOpen})}
              >
              User list <FontAwesomeIcon icon={faSortDown} rotation={this.state.userListOpen ? 180 :0}/>
            </h5>
              {
                this.state.userListOpen &&
                <table className="table table-sm table-hover">
                  <tbody>
                    {this.state.userList.filter(u => u.lastRoom == this.state.room?.id)
                      .map(u => {
                        return (
                          <tr className={cx("user-list-item", {'user-list-username-mod': (u.rank == "mod")})}>
                            <td className="user-list-username">{u.username}</td>
                            <td>
                              {(u.username != this.state.user.username)?
                              <span data-tip="Refresh this user's page" className="ml-2" onClick={this.handleRefreshUser} data-user={u.username}>
                                <FontAwesomeIcon icon={faRedo} />
                              </span>
                              : null}

                              {(u.username != this.state.user.username && u.rank != "mod")?
                              <span data-tip="Make this user a mod" className="ml-2" onClick={this.handleRankUser} data-user={u.username} data-rank="mod">
                                <FontAwesomeIcon icon={faChessKing} />
                              </span>
                              : null}
            
                              {(u.username != this.state.user.username && u.rank == "mod")?
                              <span data-tip="Remove this user's mod access" className="ml-2" onClick={this.handleRankUser} data-user={u.username} data-rank="">
                                <FontAwesomeIcon icon={faChessPawn} />
                              </span>
                              : null}

                              {this.state.room?.visibleUsers?.includes(u.username) && !this.state.room?.vimeo ?
                                <span data-tip="Show this user in the call" className="ml-2" onClick={this.handleUserVisible} data-user={u.username} data-show={false}>
                                  <FontAwesomeIcon icon={faVideoSlash} />
                                </span>
                              : null}
                              {this.state.room?.visibleUsers !== null && !this.state.room.visibleUsers.includes(u.username) && !this.state.room?.vimeo ?
                                <span data-tip="Hide this user from the call" className="ml-2" onClick={this.handleUserVisible} data-user={u.username} data-show={true}>
                                  <FontAwesomeIcon icon={faVideo} />
                                </span>
                              : null}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              }
            </div>
          </div>}
        </div>
      }
      const toSearchOption = (q) => {
        let thisTranslation = (this.state?.language && this.state.language != 'en' && q.translations?.find(tr => tr.language == this.state.language)) || null;
        return ({
          name: thisTranslation?.translatedText || q.questionText,
          value: q.questionText,
          question: q,
          translated: !!thisTranslation
        })
      };
      const treeSearchOptions = [{name: "", value: ""}];
      if (!this.state?.searchSuggestionsOnly){
        treeSearchOptions.push(
          {
            type: 'group',
            name: 'Hierarchy items',
            items:
              this.state?.survey?.questions.filter(q => q.type != 'suggestion').map(toSearchOption).sort((a,b) => a.value.localeCompare(b.value))
          }
        );
      }
      treeSearchOptions.push(
        {
          type: 'group',
          name: 'Suggestions',
          items:
            this.state?.survey?.questions.filter(q => q.type == 'suggestion').map(toSearchOption).sort((a,b) => {
              let aScore = (a.question.upvotes?.length || 0) - (a.question.downvotes?.length || 0);
              let bScore = (b.question.upvotes?.length || 0) - (b.question.downvotes?.length || 0);
              return bScore - aScore;
            }
            )
        }
      );
      contents = <div className={cx({'allow-dark': this.state?.allowDark})}>
        {this.treeMode() != 'hide' &&  <div style={{position: "absolute",
        height: "100%",
        overflow: "auto",
        left: 0,
        }}
        className={cx('tree-container',
          {'tree-container-translucent': this.treeMode() != 'treeOnly' && !!this.state?.room?.vimeo,
          'tree-container-hidden': this.treeMode() =="user" && !this.state.viewTreeUser,
          'tree-container-treeonly': this.treeMode() == 'treeOnly'})}>
          {this.state?.branding == "wcd" && <WCDHeader/>}
          <div className="container">
            
            {this.state?.room?.treeMode == "user" && <button
              type="button"
              onClick={e => this.setState({viewTreeUser: false})}
              className="btn btn-info mr-1 mt-3"
            >
              Hide Tree
            </button>}
            {intro}
            {modTools}
            {this.state?.showGuestModal &&
            <GuestLoginModal
              show={true}
              allowClose={true}
              onClose={() => this.setState({showGuestModal: false, onGuestModalLogin: null})}
              afterToken={() => {
                this.populateUserData(() => {
                  this.createConnection();
                  this.state.onGuestModalLogin && this.state.onGuestModalLogin();
                  this.setState({showGuestModal: false, onGuestModalLogin: null});
                });
              }}
            >
              <p>To add to or change the document, you must log in as a guest.</p>
            </GuestLoginModal>
            }
            { this.state?.survey &&
              <div className="mb-2 row clearfix">
                <div className="col-12 col-md-8 use-search-icon mb-2">
                  {this.state?.room?.enableSearch &&
                    <>
                      <TreeSearch
                        options={treeSearchOptions}
                        search
                        placeholder=""
                        onChanges={this.handleLinkToQuestion}
                      />
                      <FontAwesomeIcon icon={faSearch} className="search-icon" color="#888"/>
                    </>
                  }
                </div>
                {this.state?.room?.enableTranslations && <div className="language-select col-12 col-md-4">
                  <LanguageSelect
                    onChanges={value => {console.log(value); this.setState({language: value}, () => {this.populateSurveyData();})}}
                  />
                  {this.state?.openTranslationModal && 
                    <CorrectTranslationModal
                      translation={this.state.openTranslationModal?.translations?.find(tr => tr.language == (this.state.language || 'en'))}
                      item={this.state.openTranslationModal}
                      saveAndClose={this.handleCloseTranslateModal}
                      bypassLengthLimit={this.state.room?.bypassLengthLimit}
                    />
                  }
                </div>}
              </div>
            }
            {this.state?.viewRootOverride &&
            <Alert
              color="info"
            >
              You're currently viewing part of the tree outside of the focus set by the moderators.
              You can return to the focused part of the tree by clicking <a href="#" onClick={e => {e.preventDefault(); this.setState({viewRootOverride: false})}}>here</a>.
            </Alert>
            }
            {this.state?.survey?
              (this.state.room.viewRoot && !this.state.viewRootOverride)?
                this.renderQuestions(this.state.room.viewRoot, true)
              :
                this.renderQuestions(null)
            :
              <>
                <p>Loading the room...</p>
                <p>This may take a few seconds. If you're still having trouble seeing this tree after a few seconds, try refreshing the page.</p>
                <p>If you continue to see this message, then visit our <a href="https://wcd.andeye.com/help">help page</a>, where you may find a workaround, or live support from our team.</p>
                {this.state?.errors?.map(e => <div className={`alert alert-${e.type || 'secondary'}`}>{e.header && <b>{e.header}</b>} {e.message}</div>)}
                
              </>
            }
            {this.state?.language && this.state.language != 'en' && (
              <div 
                className="mt-n2 mb-2"
              >
                <a
                  href="https://translate.google.com"
                  target="_blank"
                >
                  <img
                    src={process.env.PUBLIC_URL + '/google-translate-greyscale-short.png'}
                  />
                </a>
              </div>
            )}
            {this.state?.room?.status == 'suggestion' &&
            <p>
              Use the <FontAwesomeIcon
                icon={faChevronCircleRight}
                color="#666"
              /> arrows to navigate the branches of the tree then suggest any additions by <div className="d-inline-block">pressing&nbsp;<FontAwesomeIcon icon={faPlus} className="icon-suggestion outline-button"/></div>
            </p>
            }
          </div>
        </div>}
        <div className="show-tree">
          { this.state?.room?.treeMode == "user" && !this.state.viewTreeUser &&
            <button type="submit" onClick={e => {this.setState({viewTreeUser: true})}} className="btn btn-info mr-1 mb-1">
              View Tree
            </button>
          }
          { (this.treeMode() == 'hide' || (this.treeMode() == 'user' && !this.state.viewTreeUser)) && ["73295", "113624"].includes(this.state.user?.userID) &&
            <button data-tip="Make the tree visible to all" type="submit" onClick={this.handleChangeTreeMode} className="btn btn-secondary mr-1 mb-1" data-mode="show">
              Show Tree to all
            </button>
          }
          { this.treeMode() == 'hide' && ["73295", "113624"].includes(this.state.user?.userID) &&
            <button data-tip="Make the tree visible to all" type="submit" onClick={this.handleChangeTreeMode} className="btn btn-secondary mr-1 mb-1" data-mode="user">
              Allow user control of tree
            </button>
          }
        </div>
        {this.treeMode() == 'treeOnly' || (
          (this.state?.room?.vimeo && 
            <VimeoPlayer options={{url: this.state.room.vimeo, autoplay: true}}/>
          )
          ||
          (this.state?.room?.youTube && 
            <YouTubePlayer options={{id: this.state.room.youTube, autoplay: true}}/>
          )
          ||
          
          
            <div style={{position: "absolute",
            height: "100%",
            overflow: "auto",
            width: "50%",
            right: 0}}>
              {(this.state?.user?.username && this.state?.room?.id)?
                <iframe allow="camera;microphone;display-capture" style={{border: "none", width: "100%", height: "100%"}} src={`${this.state?.callEndpoint}/room/${this.state?.room?.id}?username=${encodeURIComponent(this.state?.user?.username)}`}></iframe>
              :null}
              
            </div>
        )}
        <ReactTooltip effect="solid" type="dark"/>
      </div>;
    }
    
    return (
      contents
    );
  }

  
}