import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { faLanguage, faPencilAlt, faPlus, faQuestion, faRedoAlt, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useHistory, Link } from 'react-router-dom';
import { Badge, Button } from 'reactstrap';
import ChangeModeModal from './ChangeModeModal';
import TwilioInfo from './TwilioInfo';

function Admin(props) {

  const [rooms, setRooms] = useState([]);
  const [twilioRooms, setTwilioRooms] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const [editingRoom, setEditingRoom] = useState(null);
  let history = useHistory();

  useEffect(() => {
    loadSurveys();
    loadRooms();
    loadTwilioRooms();
  },[]);

  const loadRooms = () => {
    fetch(`/api/room`, {credentials: "include"})
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setRooms(data);
      });
  }

  useEffect(() => {
    if (rooms) {
      rooms.filter(r => r.needsUpdate).forEach(r => {
        return fetch(`/api/room/${r.id}`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(r)
        })
        .then (res => {
          if (res.ok) {
            r.needsUpdate = false;
            setRooms(prev => ([...prev.filter(room=>room.id != r.id), r]));
          }
        })
      });
    }
  },[rooms]);
  
  const loadTwilioRooms = () => {
    fetch(`https://interactive.andeye.com:9443/twilio/rooms`)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setTwilioRooms(data);
      });
  }

  const loadSurveys = () => {
    fetch(`/api/survey`, {credentials: "include"})
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setSurveys(data);
      });
  }
  const handleDupeSurvey = (e) => {
    let survey = surveys.find(s => s.id == e.currentTarget.dataset.survey);
    let newSurvey = {...survey, id: null, event: survey.event += " (Copy)"}
    
    fetch(`/api/survey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(newSurvey)
    })
    .then(loadSurveys)
  }

  const handleMakeRoom = (e) => {
    let surveyId = e.currentTarget.dataset.survey;
    let room = {survey: surveyId, status: "issue"}
    
    fetch(`/api/room`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(room)
    })
    .then(loadRooms)
  }

  const handleNewAssignedMod = (roomId, username) => {
    let thisRoom = rooms.find(r => r.id == roomId);
    thisRoom.assignedMods || (thisRoom.assignedMods = []);
    if (!thisRoom.assignedMods.includes(username)) thisRoom.assignedMods.push(username);
    thisRoom.needsUpdate = true;
    setRooms(prev => ([...prev.filter(r=>r.id != roomId), thisRoom]))
  }

  const handleRemoveAssignedMod = (roomId, username) => {
    let thisRoom = rooms.find(r => r.id == roomId);
    thisRoom.assignedMods || (thisRoom.assignedMods = []);
    thisRoom.assignedMods = thisRoom.assignedMods.filter(u => u != username);
    thisRoom.needsUpdate = true;
    setRooms(prev => ([...prev.filter(r=>r.id != roomId), thisRoom]))
  }
  const applyRoomChange = (roomId, change) => {
    console.log(change)
    if (change){
      let thisRoom = rooms.find(r => r.id == roomId);
      thisRoom = {...thisRoom, ...change, needsUpdate: true}
      setRooms(prev => ([...prev.filter(r=>r.id != roomId), thisRoom]))
    }
    setEditingRoom(null);
  }

  return (
    <div>
      <h1>Admin Dashboard</h1>
      <h2>Surveys/Documents</h2>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Duplicate</th>
            <th>Make an issues room</th>
          </tr>
        </thead>
        <tbody>
          {surveys?.map(survey => {
            return <tr key={survey.id}>
              <td>
                {survey.id}
              </td>
              <td>
                {survey.event}
              </td>
              <td>
                {/* {survey.questions.find(q => ["issue","solution", "category"].includes(q.type))? */}
                <button className="btn btn-secondary" onClick={handleDupeSurvey} data-survey={survey.id}>Duplicate</button>
                {/* :null} */}
              </td>
              <td>
                {/* {survey.questions.find(q => ["issue","solution", "category"].includes(q.type))? */}
                <button className="btn btn-secondary" onClick={handleMakeRoom} data-survey={survey.id}>Make room</button>
                {/* :null} */}
              </td>
            </tr>
          })}
        </tbody>
      </table>
      <h2>Rooms
        <button className="btn"
        onClick={(e) => {
          loadRooms();
          loadTwilioRooms();}}>
          <FontAwesomeIcon icon={faRedoAlt}/>
        </button>
      </h2>
      <table className="table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Survey</th>
            <th>Assignment</th>
            <th>Twilio</th>
            <th>Est. Assignments</th>
            <th>Assigned Mods</th>
          </tr>
        </thead>
        <tbody>
          {rooms?.sort((a,b) => (a.id > b.id) - (b.id > a.id)).map(room => {
            let survey = surveys.find((s) => s.id == room.survey);
            return <tr key={room.id}>
              <td>
                <Link to={`/sharedtree/${room.id}`}>{room.id}</Link>
                <br/>
                
                {room.status == "issue" && <Badge color="danger">Issue mode</Badge>}
                {room.status == "solution" && <Badge color="success">Solution mode</Badge>}
                {room.status == "suggestion" && <Badge color="primary">Suggestion mode</Badge>}
                {room.readOnlyMode && <Badge color="secondary">Read-only</Badge>}
                {room.treeMode && <Badge color="dark">Tree mode: {room.treeMode}</Badge>}
                {room.enableTranslations && <Badge color="info"><FontAwesomeIcon icon={faLanguage}/></Badge>}
                <a href="#" onClick={e => {e.preventDefault(); setEditingRoom(room.id)}}>
                  <Badge color="primary">
                    <FontAwesomeIcon icon={faPencilAlt}/> Edit
                  </Badge>
                </a>
                <Link to={`/admin/quiz/${room.id}`}>
                  <Badge color="info">
                    <FontAwesomeIcon icon={faQuestion}/> Quiz
                  </Badge>
                </Link>
              </td>
              <td>
                {survey? 
                  `${survey.event} (...${survey.id.substr(-6)})`
                : "None"}
              </td>
              <td>
                {room.topic?
                  room.isOpen?
                    <p>{room.topic}</p>
                  :
                    <p><i>{room.topic} (closed)</i></p>
                :null}
              </td>
              <td>
                {twilioRooms.find(tr => tr.uniqueName == room.id) && 
                <TwilioInfo
                  room={room}
                  twilioRoom={twilioRooms.find(tr => tr.uniqueName == room.id)}
                />
                }
              </td>
              <td>
                {room.testAssignedUsers?.length}
              </td>
              <td>
              
                <div>
                  <ul>
                    {room.assignedMods && room.assignedMods.map(u => {
                      return (
                      <li>
                        {u}
                        <span onClick={e => handleRemoveAssignedMod(room.id, u)}>
                          <FontAwesomeIcon icon={faTrash} color="#c00"/>
                        </span>
                      </li>)
                    })}
                  </ul>
                  <form onSubmit={e => {e.preventDefault(); handleNewAssignedMod(room.id, (new FormData(e.target)).get('newMod')); }}>
                    <div className="input-group input-group-sm">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Add Assigned Mod"
                        name="newMod"
                        />
                      <div className="input-group-append">
                        <button type="submit" className="btn btn-primary btn-sm">
                          <FontAwesomeIcon icon={faPlus} />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
                
              
              </td>
            </tr>
          })}
        </tbody>
      </table>
      {editingRoom &&
      <ChangeModeModal
        room={rooms.find(r => r.id == editingRoom)}
        saveAndClose={(changedRoom) => {applyRoomChange(editingRoom,changedRoom)}}
        treeList={surveys.map(s => ({name: `${s.event} (...${s.id.substr(-6)})`, value: s.id}))}
      />}
    </div>
  )
}

export default Admin;