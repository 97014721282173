import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { CheckboxQuestion } from './CheckboxQuestion';
import { SliderQuestion } from './SliderQuestion';
import { SuggestionQuestion } from './SuggestionQuestion';

import { faPlus, faPaperPlane, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { HubConnection } from "@microsoft/signalr";
import ProfileBar from './ProfileBar';
import ProfileEditModal from './ProfileEditModal';

export class CompleteSurvey extends Component {
  static displayName = CompleteSurvey.name;

  constructor(props) {
    super(props);
    this.state = { loading: true, creatingNew: null, newItemContent: ''};
    this.renderResponseData = this.renderResponseData.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNewItemInputChange = this.handleNewItemInputChange.bind(this);
    this.handleNewSuggestion = this.handleNewSuggestion.bind(this);
    this.showNewSuggestionEditor = this.showNewSuggestionEditor.bind(this);
    this.handleDeleteQuestion = this.handleDeleteQuestion.bind(this);
    this.submitSurveyResponse = this.submitSurveyResponse.bind(this);
    this.renderQuestions = this.renderQuestions.bind(this);
    this.handleCloseProfileEditModal = this.handleCloseProfileEditModal.bind(this);
  }

  componentDidMount() {
    console.log("mounted");
    this.populateMyProfile();
    this.populateResponseData();
  }

  populateMyProfile() {
    fetch(`/api/user`)
    .then(response => response.json())
    .then(myProfile => this.setState({myProfile}))
  }

  handleInputChange(questionText, value) {
    this.state.response.questionResponses.find(q => q.questionText == questionText).response = value.toString();
    this.forceUpdate();
  }

  handleNewItemInputChange(event) {
    this.setState({
      newItemContent: event.target.value
    });
  }

  // Note this is currently not being used due to a React state sibling leak issue yet unresolved. A workaround is implemented.
  handleDeleteQuestion(event) {
    let response = this.state.response;
    let deleteQuestionText = event.currentTarget.dataset.question
    response.questionResponses.find(qr => qr.questionText == deleteQuestionText).type = "deleted";
    response.questionResponses.filter(qr => qr.parent == deleteQuestionText).forEach(qr => {qr.type = "deleted"} )
    
    this.setState({ response });
    this.forceUpdate();
  }

  handleNewSuggestion(event) {
    event.preventDefault();
    console.log(event.currentTarget);
    let newQuestion =  "Suggestion_" + Math.random().toString(16).slice(2,8).toUpperCase()
    this.state.response.questionResponses.push({
      parent: this.state.creatingNew,
      questionText: newQuestion,
      response: this.state.newItemContent,
      type: "suggestion"
    });
    this.setState({
      creatingNew: null,
      newItemContent: ""
    });
    this.forceUpdate();
  }
  showNewSuggestionEditor(event) {
    event.preventDefault();
    console.log(event.currentTarget.dataset.parent)
    this.setState({creatingNew: event.currentTarget.dataset.parent})
  }

  handleCloseProfileEditModal = (myProfile) => {
    if (myProfile) {
      this.setState({myProfile});
      fetch(`/api/user`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(myProfile)
      })
    }
  }

  renderResponseData(response) {
    return (
      <div>
        <div className="row mb-3">
          <div className="col-6 col-md-8">
            <h2>Water Climate Discussion interests</h2>
          </div>
          {this.state?.myProfile &&
          <ProfileBar
            myProfile={this.state.myProfile}
            setMyProfile={(myProfile) => this.setState({myProfile})}
          />
          }
        </div>
        <div className="row">
          <div className="col-12 col-md-6 pb-2">
            {this.state?.myProfile &&
            <ProfileEditModal 
              myProfile={this.state.myProfile}
              onClose={this.handleCloseProfileEditModal}
              inline={true}
              autosave={true}
            />
            }
          </div>
          <div className="col-12 col-md-6">
            <p>
              Please use the sliders below to show your interest in discussing the
              listed topics. This information will be used to match you to similar
              participants during the conference networking.
            </p>
            <p>
              Turning up some of the sliders may reveal additional sub-categories.
              If something isn't listed which you think should be, use the '+'
              button after increasing a slider, and add a suggested sub-category.
              You can add as many of these as you'd like, including adding
              suggestions under those which you have already created.
            </p>
            <p>
              When you are finished, press Submit, and your inputs and suggestions
              will be stored.
            </p>
          </div>
        </div>
        <div>
          {this.renderQuestions(null)}
          <button onClick={this.submitSurveyResponse} type="submit" className="btn btn-primary">Submit</button>
        </div>
      </div>
    );
  }

  renderQuestions(parentQuestion) {
    let elements = [];
    if (parentQuestion == "Suggestion_1BE117") console.log("RENDERING Suggestion_1BE117");
    // Find each question at this level (or all root question if this is the top level)
    this.state.response.questionResponses.filter(qr => (qr.parent == parentQuestion)).map(qr => {
      let childElements = this.renderQuestions(qr.questionText);
      if (qr.type == 'slider') {
        // Create the slider for this question
        elements.push(<div className="slider-container">
          <SliderQuestion onValueChange={this.handleInputChange} question={qr} showText={true} mode="edit" key={qr.questionText}/>
          {qr.response > 8 ? 
          <span onClick={this.showNewSuggestionEditor} data-parent={qr.questionText} type="button" className="ml-3 response-add-question-inline">
            <FontAwesomeIcon icon={faPlus} />
          </span>
          : null}
          
          </div>);
        
      } else if (qr.type == 'suggestion') {
        elements.push(<div className="mb-2">
            <SuggestionQuestion
              mode="view"
              maxLength="80"
              onValueChange={this.handleInputChange}
              question={qr}
              focus={qr.questionText == this.state.newQuestion}
            />
            <span onClick={this.showNewSuggestionEditor} data-parent={qr.questionText} type="button" className="ml-2 response-add-question-inline">
              <FontAwesomeIcon icon={faPlus} />
            </span>
            {!childElements?
              <span onClick={this.handleDeleteQuestion} data-question={qr.questionText} type="button" className="ml-2 response-add-question-inline">
                <FontAwesomeIcon icon={faTrash} />
              </span>
            :null}
          </div>)
      } else if (qr.type == 'deleted') {
        elements.push(null);
      }
      // Run this algorithm recursively to get the sliders for sub-categories
      let childSuggestions = this.state.response.questionResponses.filter(r => (r.parent == qr.questionText && r.type == 'suggestion' && r.response != "DELETED"))
      if (childElements && (qr.type != "slider" || qr.response > 8 || childSuggestions.length)) {
        elements.push(childElements);
      }
       else {
        elements.push(null);
       }
    });
    if (this.state.creatingNew && this.state.creatingNew == parentQuestion) {
      elements.push(<form onSubmit={this.handleNewSuggestion}>
        <div className="form-group">
          <div className="input-group suggestion-input mt-2">

            <input
              maxLength={this.state.newItemMaxLength}
              autoFocus
              value={this.state.newItemContent}
              onChange={this.handleNewItemInputChange}
              type="text"
              id="new-item-input"
              className="form-control"
              placeholder={`Add a suggestion`}
            />

            <div className="input-group-append">
              <button type="submit" className="btn btn-primary">
                <FontAwesomeIcon icon={faPaperPlane} />
              </button>
            </div>
          </div>
          {this.state.newItemContent.length == this.state.newItemMaxLength?
            <small className="form-text text-muted">Maximum text length reached</small>
          : null}
        </div>
      </form>);
    }

    // Create a suggestion button for the root node
    // if (!parentQuestion) {
    //   elements.push(<p onClick={this.handleNewSuggestion} data-parent="root" className="response-add-question">Suggest another topic</p>)
    // }
    if (elements.filter(e => (e != null)).length) {
      return <div className="question-list">{elements}</div>;
    } else { return null; }
  }

  async submitSurveyResponse(event){
    event.preventDefault();
    let responseToSubmit = Object.assign({}, this.state.response);
    responseToSubmit.questionResponses = responseToSubmit.questionResponses.filter((qr) => (qr.type != "deleted"));
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(responseToSubmit)
    }
    console.log(responseToSubmit);
    const response = await fetch('/api/surveyresponse/' + this.state.response.id, requestOptions);
    
    this.setState({redirectToResults: true});
  }

  render() {
    if (this.state.redirectToResults) {
      // Temporary change to the matcher
      return (<div>
        <p>Your results and suggestions have been saved. You can return to this page at any time to view or update your response.</p>
      </div>);
      // return <Redirect to={'/matches/' + this.state.response.id} />
    }
    let contents = this.state.loading
      ?
        <div>
          <p><em>Loading Networking Survey. This should only take a moment...</em></p>
          {this.state.error?
            <p className="alert alert-danger">{this.state.error}</p>
          :null}

        </div>
      : this.renderResponseData(this.state.response);

    return (
      <div>
        {contents}
      </div>
    );
  }

  async populateResponseData() {
    
    const response = await fetch('api/surveyresponse/' + this.props.match.params.id);
    if (!response.ok) {
      let message = <span>There was an unexpected error. Please refresh, try again later or contact andeye at info@andeye.com for assistance</span>
      if (response.status == 401) {
        message = <span>We couldn't find your login credentials. Go to the registration page, ensure you are logged in, and return to this event.</span>
      }
      this.setState({error: message})
    }
    const data = await response.json();
    const surveyResponse = await fetch('api/survey/' + data.survey);
    const surveyData = await surveyResponse.json();

    let newResponses = {};
    if (!data.questionResponses) {
      newResponses = surveyData.questions.map(question => {
        return {
          questionText: question.questionText,
          type: question.type,
          response: "0",
          parent: question.parent
        }
      })
    } else {
      newResponses = surveyData.questions.map(question => {
        return {
          questionText: question.questionText,
          type: question.type,
          response: data.questionResponses.find(qr => qr.questionText == question.questionText)?.response || "0",
          parent: question.parent
        }
      })
    }
    data.questionResponses = newResponses;

    console.log(data);
    this.setState({ response: data, survey: surveyData, loading: false });
  }
}
