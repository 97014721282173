
import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash, faCheck, faTimes, faTrashAlt, faPlay } from '@fortawesome/free-solid-svg-icons';

import { Button, Input } from 'reactstrap';

function QuestionRow(props) {

  const [newMcqOption, setNewMcqOption] = useState("");
  const [newAnswer, setNewAnswer] = useState("");

  const runQuestion = () => {
    return fetch(`/api/room/${props.roomId}/quiz`, {
      credentials: 'include',
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: props.roomId,
        activeQuizQuestion: props.question.id
      })
    })
  }

  // Change the question type (handles the dropdown change) and clear the options/answers fields
  const changeType = (e) => {
    let newType = e.currentTarget.value;
    console.log(newType);
    props.setQuestion({...props.question, type: newType, mcqOptions: [], answers: [], changed: true});
  }

  // Add an option to an MCQ question (handles the submission of the input box for MCQs)
  const handleNewMcqOption = (e) => {
    e.preventDefault();
    let mcqOptions = props.question.mcqOptions || [];
    mcqOptions.push(newMcqOption);
    setNewMcqOption("");
    props.setQuestion({...props.question, mcqOptions, changed: true})
  }

  // Remove an option (runs on click of the delete button)
  const handleRemoveMcqOption = (a) => {
    let mcqOptions = props.question.mcqOptions || [];
    mcqOptions = mcqOptions.filter(ans => ans != a);
    props.setQuestion({...props.question, mcqOptions, changed: true})
  }

  // Mark an MCQ option as correct by adding it to the answer array
  const handleCorrectMCQ = (a) => {
    let answers = props.question.answers || [];
    if (!answers.includes(a)) answers.push(a);
    props.setQuestion({...props.question, answers, changed: true})
  }

  // Mark an MCQ option as incorrect by removing from the answer array
  const handleInorrectMCQ = (a) => {
    let answers = props.question.answers || [];
    answers = answers.filter(ans => ans != a);
    props.setQuestion({...props.question, answers, changed: true})
  }

  // Add an answer (for text questions), handles the submission of the input field
  const handleNewAnswer = (e) => {
    e.preventDefault();
    let answers = props.question.answers || [];
    if (!answers.includes(newAnswer)) answers.push(newAnswer);
    setNewAnswer("");
    props.setQuestion({...props.question, answers, changed: true})
  }

  // Remove an answer (text questions)
  const handleRemoveAnswer = (a) => {
    let answers = props.question.answers || [];
    answers = answers.filter(ans => ans != a);
    props.setQuestion({...props.question, answers, changed: true})
  }

  return (
    <tr>
      <td>
        {/* Question Text column */}
        <input
          className="form-control"
          type="text"
          value={props.question.questionText}
          onChange={e => { let questionText = e.target.value; props.setQuestion({...props.question, questionText})}}
          onBlur={e => props.setQuestion({...props.question, changed: true})}
          />
      </td>
      <td>
        {/* Question Type column */}
        <Input
          type="select"
          value={props.question.type}
          onChange={changeType}
          >
            <option value="mcq">Multi-choice</option>
            <option value="text">Text</option>
        </Input>
      </td>
      <td>
        {/* Answers column */}
        {props.question.type == "mcq"?
        // If MCQ, list options
          <div>
            <ul>
              {props.question.mcqOptions?.map(a => {
                return (
                <li>
                  {a} {props.question.answers?.includes(a)?
                    // Cross to mark as incorrect if it is currently correct
                    <span onClick={e => handleInorrectMCQ(a)}>
                      <FontAwesomeIcon icon={faTimes} color="#c00"/>
                    </span>
                  :
                    // Tick to mark as correct if currently not correct
                    <span onClick={e => handleCorrectMCQ(a)}>
                      <FontAwesomeIcon icon={faCheck} color="#080"/>
                    </span>
                  } <span onClick={e => handleRemoveMcqOption(a)}>
                    <FontAwesomeIcon icon={faTrash} color="#c00"/>
                  </span>
                  {/* Above: trash icon to remove the options */}
                </li>)
              })}
            </ul>
            {/* Form to submit new MCQ option */}
            <form onSubmit={handleNewMcqOption}>
              <div className="input-group input-group-sm">
                <input
                  className="form-control"
                  type="text"
                  placeholder="Add MCQ option"
                  value={newMcqOption}
                  onChange={e => setNewMcqOption(e.target.value)}
                  />
                <div className="input-group-append">
                  <button type="submit" className="btn btn-primary btn-sm">
                    <FontAwesomeIcon icon={faPlus} />
                  </button>
                </div>
              </div>
            </form>
          </div>
          
        : null}
        {/* If a text-based question */}
        {props.question.type == "text"?
          <div>
          <ul>
            {props.question.answers.map(a => {
              return (
              <li>
                {/* List the possible answers with a trash icon to remoev them */}
                {a}
                <span className="ml-2" onClick={e => handleRemoveAnswer(a)}>
                  <FontAwesomeIcon icon={faTrash} color="#c00"/>
                </span>
              </li>)
            })}
          </ul>
          {/* Form to add new answers */}
          <form onSubmit={handleNewAnswer}>
            <div className="input-group input-group-sm">
              <input
                className="form-control"
                type="text"
                placeholder="Add Answer"
                value={newAnswer}
                onChange={e => setNewAnswer(e.target.value)}
                />
              <div className="input-group-append">
                <button type="submit" className="btn btn-primary btn-sm">
                  <FontAwesomeIcon icon={faPlus} />
                </button>
              </div>
            </div>
          </form>
        </div>
        : null}
      </td>
      <td>
        {/* Button to delete question, calls parent component function */}
        <Button type="button" color="danger" onClick={(e) => props.handleDelete(props.question.id)}>
          <FontAwesomeIcon icon={faTrashAlt}/>
        </Button> {props.roomId && !props.currentlyActive && <Button type="button" color="success" onClick={runQuestion}>
          <FontAwesomeIcon icon={faPlay}/>
        </Button>}
      </td>
    </tr>
)

}

export default QuestionRow