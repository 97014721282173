import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'reactstrap';

function ProfileEditModal(props) {

  const [myProfile, _setMyProfile] = useState(props.myProfile);
  const myProfileRef = useRef(props.myProfile);

  const handleNicknameChange = (e) => {
    let newProfile = {...myProfileRef.current, nickname: e.currentTarget.value}
    myProfileRef.current = newProfile;
    _setMyProfile(newProfile);
  }

  const handleSummaryChange = (e) => {
    let newProfile = {...myProfileRef.current, summary: e.currentTarget.value}
    myProfileRef.current = newProfile;
    _setMyProfile(newProfile);
  }

  const handleWantsToTalkChange = (e) => {
    let newProfile = {...myProfileRef.current, wantsToTalk: e.currentTarget.value}
    myProfileRef.current = newProfile;
    _setMyProfile(newProfile);
  }

  const handleSaveProfile = (e) => {
    console.log(myProfile);
    props.onClose(myProfile);
  }

  const handleAutoSave = (e) => {
    if (props.autosave) handleSaveProfile(e);
  }

  const form = <>
    <div class="form-group">
      <label for="displayNameInput">Display Name</label>
      <input
        maxLength="60"
        type="text"
        class="form-control"
        id="displayNameInput"
        placeholder="Enter a display name (max 60 characters)"
        value={myProfile.nickname}
        aria-describedby="displayNameInputHelp"
        onChange={handleNicknameChange}
        onBlur={handleAutoSave}
      />
      <small id="displayNameInputHelp" class="form-text text-muted">
        Enter your name as you'd like it to be seen by others.
      </small>
    </div>
    <div class="form-group">
      <label for="summaryInput">Role</label>
      <input
        maxLength="100"
        type="text"
        class="form-control"
        id="summaryInput"
        placeholder="Enter your role (max 100 characters)"
        value={myProfile.summary}
        aria-describedby="summaryInputHelp"
        onChange={handleSummaryChange}
        onBlur={handleAutoSave}
      />
      <small id="summaryInputHelp" class="form-text text-muted">
        Please enter your job title and company.
      </small>
    </div>
    <div class="form-group">
      <label for="talkInput">I want to discuss...</label>
      <input
        maxLength="100"
        type="text"
        class="form-control"
        id="talkInput"
        placeholder="Enter up to 100 characters"
        value={myProfile.wantsToTalk}
        aria-describedby="talkInputHelp"
        onChange={handleWantsToTalkChange}
        onBlur={handleAutoSave}
      />
      <small id="talkInputHelp" class="form-text text-muted">
        Tell everyone what you'd like to discuss during day 3 networking.
      </small>
    </div>
  </>;

  const fullContents = <>
    <div class="modal-header">
      <h5 class="modal-title">Edit Profile</h5>
      {!props.inline && <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e => props.onClose()}>
        <span aria-hidden="true">&times;</span>
      </button>}
    </div>
    <div class="modal-body">
      {form}
    </div>
    {!(props.autosave && props.inline) && <div class="modal-footer">
      {!props.autosave && <button type="button" class="btn btn-primary" onClick={handleSaveProfile}>Save Changes</button>}
      {!props.inline && <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={e => props.onClose()}>Close</button>}
    </div>}
  </>;

  if (myProfile) {
    if (props.inline){
      return <div className="modal-content">{fullContents}</div>;
    } else {
      return <Modal isOpen={true}>{fullContents}</Modal>
    }
  } else return null;
}

export default ProfileEditModal;