import React, { Component } from 'react';
import ReactBootstrapSlider from 'react-bootstrap-slider';

export class SliderQuestion extends Component {
  static displayName = SliderQuestion.name;

  constructor(props) {
    super(props);
    this.state = {
      ...this.props,
      currentValue: props.question.response || this.props.default || 0
    };
    this.handleChange = this.handleChange.bind(this);
    
    //Workaround
    if (this.state.currentValue == 0) {
      this.state.onValueChange(this.props.question.questionText, this.state.currentValue);
    }
  }

  handleChange(event) {
    this.setState({currentValue: event.target.value}, () => {
      this.state.onValueChange(this.props.question.questionText, this.state.currentValue);
    });
    //this.forceUpdate();
  }

  render() {

    if (this.props.mode == "view") {
      return (
        <p>
          <em>{this.props.question.questionText}</em>
        </p>
      );
    }
    else {
      
      return (
        <div className="form-group d-inline-block">
          {this.state.showText?
            <label className="sliderquestion-label">
              {this.props.question.questionText}
            </label>
          :null}
          
          <ReactBootstrapSlider 
            {...this.state}
            value={this.state.currentValue}
            
            slideStop={this.handleChange}
            max={20}
            tooltip="hide"
            />
        </div>
          
      )
    }
  }


}