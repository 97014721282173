import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'reactstrap';

import styles from './ExternalCallJoinModal.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

function ExternalCallJoinModal(props) {

  const fullContents = <>
    <div class="modal-header">
      <h5 class="modal-title">Join external call</h5>
    </div>
    <div class="modal-body">
      <p>
        <i>{props.creatorInfo?.nickname || props.callRoom.creator}</i>'s room is hosted outside of the
        andeye platform. They have provided the following link:
      </p>
      <p className={styles.externalLink}>
        <a href={props.callRoom.externalLink} target="_blank">{props.callRoom.externalLink}</a>
      </p>
      <p>
        andeye and event organisers are not responsible for external links,
        please satisfy yourself that the link is genuine before clicking.
      </p>
      <p>
        Leave this pop-up open while in the call, and you will be shown as 'in a call'
        to other event participants. If you have left the call, click below
      </p>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger"  onClick={props.onClose} data-call={props.callRoom.id}>Left call</button>
    </div>
  </>;

  return <Modal isOpen={true}>{fullContents}</Modal>;
}

export default ExternalCallJoinModal;