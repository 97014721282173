import React, { useState, useEffect, useRef } from 'react';
import { FormText, Modal } from 'reactstrap';
import {langName} from '../collaborate/LanguageSelect'

export default function CorrectTranslationModal(props) {
  
  const [translation, setTranslation] = useState(props.translation.translatedText);

  const save = (e) => {
    if (translation == props.translation.translatedText){
      props.saveAndClose();
    } else {
      props.saveAndClose(translation);
    }
  }
  const close = (e) => {
    props.saveAndClose();
  }

  const fullContents = <>
  <div class="modal-header">
    <h5 class="modal-title">Correct This Translation</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={close}>
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p><b>{langName(props.item.language || 'en')}:</b> {props.item.questionText}</p>
    <div class="form-group">
      <label for="translationInput">Translated text:</label>
      <input
        maxLength={props.bypassLengthLimit? null : "120"}
        type="text"
        class="form-control"
        id="translationInput"
        aria-describedby="translationInputHelp"
        placeholder="Suggest a translation"
        value={translation}
        onChange={e => setTranslation(e.target.value)}
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      data-dismiss="modal"
      onClick={save}
      disabled={false}
    >
      Save
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      data-dismiss="modal"
      onClick={close}
    >
      Cancel
    </button>
  </div>
</>;

  return (
    <Modal isOpen={true}>{fullContents}</Modal>
  )
}