import React, { useState, useEffect, useRef } from 'react';
import styles from './WCDHeader.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

export default function WCDHeader(props) {

  return (
    <div class={styles.parallax}>
      <div class={styles.caption}>
        <h1><span class={styles.parallaxHeader}>Water Climate Discussion</span></h1>
      </div>
    </div>
    )
}