import React, { Component } from 'react';

export class CheckboxQuestion extends Component {
  static displayName = CheckboxQuestion.name;

  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    let newValue = event.target.checked
    this.props.onValueChange(this.props.question.questionText, newValue);
    this.forceUpdate();
  }

  render() {

    if (this.props.mode == "view") {
      return (
        <p>
          <em>{this.props.question.questionText}</em>
        </p>
      );
    }
    else {
      let checked = null;
      if (this.props.question.response) {
        checked = true;
      }
      return (
        <div className="form-check">
          <input className="form-check-input" type="checkbox" onChange={this.handleChange} checked={checked}/>
          <label className="form-check-label">
            {this.props.question.questionText}
          </label>
        </div>
      )
    }
  }


}