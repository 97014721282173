import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'reactstrap';
import styles from './ExternalCallInfoModal.module.css';
import classNames from 'classnames/bind';

let cx = classNames.bind(styles);

function ExternalCallInfoModal(props) {

  const [myProfile, _setMyProfile] = useState(props.myProfile);
  const [linkError, setLinkError] = useState(null);
  const myProfileRef = useRef(props.myProfile);

  const handleUseExternalVideoChange = (e) => {
    let newProfile = {...myProfileRef.current, useExternalVideo: e.currentTarget.checked}
    myProfileRef.current = newProfile;
    _setMyProfile(newProfile);
    console.log(newProfile);
  }
  const handleExternalCallLinkChange = (e) => {
    let newProfile = {...myProfileRef.current, externalCallLink: e.currentTarget.value}
    myProfileRef.current = newProfile;
    try {
      let url = new URL(e.currentTarget.value)
      setLinkError(null);
    } catch (e) {
      console.log(e);
      setLinkError(true);
    }
    _setMyProfile(newProfile);
    console.log(newProfile);
  }
  const handleSaveProfile = (e) => {
    console.log(myProfile);
    props.onClose(myProfile);
  }

  const handleAutoSave = (e) => {
    if (props.autosave) handleSaveProfile(e);
  }

  const form = <>
    <p>
      If you can’t use andeye Video you can switch to requesting that others
      join you on Zoom, Teams, or another platform
    </p>
    <div className="form-check mb-2">
      <input id="useExternal" className="form-check-input" type="checkbox" onChange={handleUseExternalVideoChange} checked={myProfile.useExternalVideo}/>
      <label for="useExternal" className="form-check-label">
        Yes, please use external meeting software when I send invites 
      </label>
    </div>
    {myProfile.useExternalVideo && 
    <div>
      <p className={styles.stepLine}>
        1. Open a Teams/Zoom/other call and start your video
      </p>
      <p className={styles.stepLine}>
        2. Copy the link to the call
      </p>
      <div class="form-group">
        <label for="externalCallLinkInput">3. Paste the link in here</label>
        <input
          type="text"
          class={cx("form-control", {"is-invalid": (!myProfile.externalCallLink?.length || linkError)})}
          id="externalCallLinkInput"
          placeholder="Enter a link to your meeting room"
          value={myProfile.externalCallLink}
          aria-describedby="externalCallLinkInputHelp"
          onChange={handleExternalCallLinkChange}
          onBlur={handleAutoSave}
        />
        {!myProfile.externalCallLink?.length && 
        <div class="invalid-feedback">
          Please enter a meeting room link before saving, or uncheck this feature.
        </div>
        }
        {!!myProfile.externalCallLink?.length && linkError &&
        <div class="invalid-feedback">
          Your link is invalid. Please try pasting the link again
        </div>
        }
      </div>
      <p className={styles.stepLine}>
        4. Click save changes below
      </p>
      <p className={styles.stepLine}>
        5. Click 'Invite to {props.meetingInfo(myProfile.externalCallLink)?.serviceName || 'external'} call' for each person you'd like to join your call
      </p>
      <p className={styles.stepLine}>
        6. <b>Important:</b> Ensure you click 'Mark Call as Ended' on the system when you finish your {props.meetingInfo(myProfile.externalCallLink)?.serviceName || 'external'} call so others aren't sent to an empty room
      </p>
    </div>
    }

  </>;

  const fullContents = <>
    <div class="modal-header">
      <h5 class="modal-title">Change video platform</h5>
      {!props.inline && <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e => props.onClose()}>
        <span aria-hidden="true">&times;</span>
      </button>}
    </div>
    <div class="modal-body">
      {form}
    </div>
    {!(props.autosave && props.inline) && <div class="modal-footer">
      {!props.autosave && <button type="button" class="btn btn-primary" onClick={handleSaveProfile} disabled={myProfile.useExternalVideo && (!myProfile.externalCallLink?.length || linkError)}>Save Changes</button>}
      {!props.inline && <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={e => props.onClose()}>Close</button>}
    </div>}
  </>;

  if (myProfile) {
    if (props.inline){
      return <div className="modal-content">{fullContents}</div>;
    } else {
      return <Modal isOpen={true}>{fullContents}</Modal>
    }
  } else return null;
}

export default ExternalCallInfoModal;