import React, { Component } from 'react';

import { Link, Redirect } from 'react-router-dom';

export class Home extends Component {
  static displayName = Home.name;
  static testUsers = ['Andy', 'Bob', 'Charlie', 'Doris', 'Edwina', 'Frances']

  constructor(props) {
    super(props);
    this.state = { loading: true};
    this.renderSurveyList = this.renderSurveyList.bind(this);
    this.createSurveyResponse = this.createSurveyResponse.bind(this);
  }

  componentDidMount() {
    this.populateSurveyList();
  }

  render () {
    if (this.state.createdSurveyResponse) {
      return <Redirect to={'/surveyresponse/' + this.state.createdSurveyResponse} />
    }

    let contents = this.state.loading
      ? <p><em>Loading Networking Survey...</em></p>
      : this.renderSurveyList(this.state.surveys);

    return (
      <div>
        {contents}
      </div>
    );
  }

  renderSurveyList(surveys) {
    let questionitems = [];
    return (
      <div>
        <h2>Event surveys for matching</h2>
        <i>This page is for development, when implemented into the andeye platform the user will be entered into the correct survey by default.</i>
        <div>
          {surveys.map(survey => <a className="survey-link link-primary" data-surveyid={survey.id} onClick={this.createSurveyResponse}>{survey.event}</a>)}
        </div>
      </div>
    );
  }

  async createSurveyResponse(event) {
    event.preventDefault();
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"survey": event.target.getAttribute('data-surveyid')})
    }
    const response = await fetch('/api/surveyresponse', requestOptions);
    const surveyResponse = await response.json();
    this.setState({createdSurveyResponse: surveyResponse.id});
    
  }

  async populateSurveyList() {
    const response = await fetch('api/survey/');
    const data = await response.json();
    this.setState({ surveys: data, loading: false });
  }
}
