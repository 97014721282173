import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'reactstrap';
//import styles from './RecorderLinkButtonAndModal.module.css';
//import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import { faAt, faVideo } from '@fortawesome/free-solid-svg-icons';

//let cx = classNames.bind(styles);

export default function GuestLoginModal(props) {
  
  const [showModal, setShowModal] = useState(props.show || null);
  const [email, setEmail] = useState(null);
  const [displayName, setDisplayName] = useState(null);
  const [agree, setAgree] = useState(false);

  const isValidEmail = (test) => {
    return !!test && test.includes('@');
  }

  const getToken = () => {
    fetch(`/api/user/guestkey`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({email: email, username: displayName})
    })
    .then(() => {
      if (props.afterToken) {
        setShowModal(false);
        props.afterToken();
      } else {
        fetch(`/api/user`)
        .then(() => {
          window.location.reload();
        })
      }
    })
  }

  const fullContents = <>
  <div class="modal-header">
    <h5 class="modal-title">Access as a guest</h5>
    {props.allowClose && <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e => props.onClose && props.onClose()}>
      <span aria-hidden="true">&times;</span>
    </button>}
  </div>
  <div class="modal-body">
    {props.children || 
    <>
      <p>You are not logged in.</p>
      <p>You can access this page as a guest. Please enter your name and email below</p>
    </>}
    <div class="form-group">
      <label for="displayNameInput">Name</label>
      <input
        maxLength="60"
        type="text"
        class="form-control"
        id="displayNameInput"
        aria-describedby="displayNameInputHelp"
        placeholder="Enter your name"
        value={displayName}
        onChange={e => setDisplayName(e.target.value)}
      />
      {!displayName && <small id="displayNameInputHelp" class="form-text text-muted">
        Enter your name
      </small>}
    </div>
    <div class="form-group">
      <label for="emailInput">Email</label>
      <input
        maxLength="100"
        type="text"
        class="form-control"
        id="emailInput"
        aria-describedby="emailInputHelp"
        placeholder="Enter your email"
        value={email}
        onChange={e => setEmail(e.target.value)}
      />
      {!isValidEmail(email) && <small id="emailInputHelp" class="form-text text-muted">
        Enter a valid email.
      </small>}
    </div>
    <div className="form-check mb-2">
      <input id="useExternal" className="form-check-input" type="checkbox" onChange={e => {setAgree(e.currentTarget.checked)}} checked={agree}/>
      <label for="useExternal" className="form-check-label">
        I have read and agree to the <a href="https://wcd.andeye.com/terms" target="_blank">andeye Terms of Service</a> &amp; <a href="https://wcd.andeye.com/privacy" target="_blank">Privacy Policy</a>.
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      data-dismiss="modal"
      onClick={getToken}
      disabled={!displayName || !isValidEmail(email) || !agree}
    >
      Continue as guest
    </button>
    {props.allowClose &&
      <button
        type="button"
        class="btn btn-secondary"
        data-dismiss="modal"
        onClick={e => props.onClose && props.onClose()}
      >
        Close
      </button>
    }
    
  </div>
</>;

  return (
      <Modal isOpen={showModal}>{fullContents}</Modal>
    )
}