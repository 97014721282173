import React, { Component } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { CheckboxQuestion } from './CheckboxQuestion';
import MatchResultItem from './MatchResultItem';
import { CallWindow } from '../call/CallWindow';

export class ViewMatches extends Component {
  static displayName = ViewMatches.name;

  constructor(props) {
    super(props);
    this.state = { loading: true};
    this.renderMatchData = this.renderMatchData.bind(this);
    this.populateMatchData = this.populateMatchData.bind(this);
    this.openCallWindow = this.openCallWindow.bind(this);
    this.onHangUp = this.onHangUp.bind(this);
  }

  componentDidMount() {
    this.populateMatchData();
    setInterval(this.populateMatchData, 2000);
  }

  renderMatchData(matches) {
    matches.sort((a,b) => {
      // If one is a current call, bump it up
      if (a.currentCall) return -1;
      if (b.currentCall) return 1;
      // Else, if one has a higher score, bump it up
      if (a.score < b.score) return 1;
      if (b.score < a.score) return -1;
      // Else, keep the sort consistent by sorting by hash
      return a.pairId < b.pairId && 1 || -1;
    });
    return (
      <div>
        <p>
          Users online, ranked by your shared Interests
          <span className="match-list-refresh" onClick={this.populateMatchData}>Refresh this list</span>
        </p>
        <div className="match-result-list">
          {matches.map(match => <MatchResultItem match={this.state.hashedMatches[match.pairId]} mode="view" openCallWindow={this.openCallWindow}/>)}
        </div>
      </div>
    );
  }

  openCallWindow(match) {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: this.props.match.params.id,
        currentCall: match.user
      })
    }
    console.log(this.state.response);
    fetch('/api/surveyresponse/' + this.props.match.params.id, requestOptions);
    this.setState({call: match});
  }

  onHangUp(pairId) {
    const requestOptions = {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: this.props.match.params.id,
        currentCall: null
      })
    }
    console.log(this.state.response);
    fetch('/api/surveyresponse/' + this.props.match.params.id, requestOptions);
    this.setState({call: null});
  }

  render() {

    let contents = this.state.loading
      ? <p><em>Loading Matches...</em></p>
      : this.renderMatchData(this.state.matches);
    let callwindow = this.state.call
      ? <CallWindow match={this.state.call} onHangUp={this.onHangUp}/>
      : null;
    return (
      <div>
        {contents}
        {callwindow}
      </div>
    );
  }

  async populateMatchData() {
    const response = await fetch('api/matchresult/' + this.props.match.params.id);
    const data = await response.json();
    let hashedData = {};
    data.forEach(element => {
      hashedData[element.pairId] = element;
    });
    console.log(data);
    console.log(hashedData);
    this.setState({ matches: data, hashedMatches: hashedData, loading: false });
  }
}
