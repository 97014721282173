import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { ViewSurvey } from './components/survey/ViewSurvey';
import { ViewMatches } from './components/survey/ViewMatches';
//import { Register } from './components/Register';

import './custom.css'
import { CompleteSurvey } from './components/survey/CompleteSurvey';
import Cookies from 'universal-cookie';
import { NewSurveyResponse } from './components/survey/NewSurveyResponse';
import { SharedTree } from './components/collaborate/SharedTree';
import GuestRoom from './components/collaborate/GuestRoom';
import Admin from './components/admin/Admin';
import SuggestionTable from './components/admin/SuggestionTable'
import MatcherRoom from './components/survey/MatcherRoom';
import PersonalRecorder from './components/recorder/PersonalRecorder';
import RoundEditor from './components/quiz/RoundEditor';
import TeamsConfig from './components/teams/TeamsConfig';

const cookies = new Cookies();

export default class App extends Component {
  static displayName = App.name;

  render () {
    return (
      <Layout>
        <Route exact path='/' component={Home} />
        <Route path='/(sharedtree|room)/:id' component={SharedTree} />
        <Route path='/survey/:id' component={ViewSurvey} />
        <Route path='/respond/:id' component={NewSurveyResponse} />
        <Route path='/surveyresponse/:id' component={CompleteSurvey} />
        <Route path='/matches/:id' component={ViewMatches} />
        <Route path='/matcher/:surveyId' component={MatcherRoom} />
        <Route path='/recorder' component={PersonalRecorder} />
        <Route path='/guestroom/:roomId' component={GuestRoom} />
        <Route path='/admin' exact component={Admin} />
        <Route path='/admin/quiz/:roomId?' component={RoundEditor} />
        <Route path='/suggestions/:surveyId' component={SuggestionTable} />
        <Route path='/teams_config.html' exact component={TeamsConfig} />
        {/* <Route path='/:eventname/register' component={Register} /> */}
      </Layout>
    );
  }
}
