import React from 'react';

export default function TreeLink(props) {

  console.log(props);
  if (props.href.charAt(0) === '#') {
    return <a href='#' onClick={e => {e.preventDefault(); props.handleLinkToQuestion(decodeURI(props.href.substr(1)).replace('_',' '))}}>{props.children}</a>
  }
  return (
    <a {...props} />
    )
}