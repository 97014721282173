import React, { useState, useEffect, useRef } from 'react';
import { Modal } from 'reactstrap';
//import styles from './RecorderLinkButtonAndModal.module.css';
//import classNames from 'classnames/bind';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import { faAt, faVideo } from '@fortawesome/free-solid-svg-icons';

//let cx = classNames.bind(styles);

export default function RecorderLinkButtonAndModal(props) {
  
  const [showModal, setShowModal] = useState(null);

  const fullContents = <>
  <div class="modal-header">
    <h5 class="modal-title">Record your Action Pledge</h5>
    {!props.inline && <button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={e => {setShowModal(false)}}>
      <span aria-hidden="true">&times;</span>
    </button>}
  </div>
  <div class="modal-body">
    <p>Please try to keep your pledge as succinct as possible (&lt;1 minute)</p>
    <p>You may want to start your Action Pledge by saying "I’m [name] and I pledge to...”</p>
    <p>You can record your pledge directly on the andeye system here:</p>
    <p className="text-center">
      <a
        className="btn btn-primary"
        href="/recorder"
        target="_blank"
      >
        Open the Action Pledge Recorder
      </a>
    </p>
    <p>
      Otherwise you can upload your pledge:
      <a
        className="btn btn-info btn-sm ml-2"
        href="https://wcd.andeye.com/upload"
        target="_blank"
      >
        <FontAwesomeIcon icon={faDropbox}/> Upload your Pledge
      </a>
    </p>
    <p>
      If neither work for you, get in touch:
      <a
        className="btn btn-info btn-sm ml-2"
        href="mailto:help@andeye.com"
        target="_blank"
      >
        <FontAwesomeIcon icon={faAt}/> Email help@andeye.com
      </a>
    </p>
    <p>
      <small>By submitting a pledge you give andeye Productions Ltd permission to edit, collate and distribute your pledge via any medium</small>
    </p>
  </div>
  {!(props.autosave && props.inline) && <div class="modal-footer">
    {!props.inline && <button type="button" class="btn btn-secondary" data-dismiss="modal" onClick={e => {setShowModal(false)}}>Close</button>}
  </div>}
</>;

  return (
    <>
      <button
        type="button"
        className="btn btn-danger btn-sm mr-2 mb-2"
        onClick={e => {setShowModal(true)}}
      >
        <FontAwesomeIcon icon={faVideo}/> Record your Action Pledge
      </button>
      <Modal isOpen={showModal}>{fullContents}</Modal>
    </>
    )
}