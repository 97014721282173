import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import styles from './QuestionPlayer.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons';

function QuestionPlayer(props) {

  // the "Response" we will deliver to the server and the admin
  // Initially set with the questionID and the username but nothing else
  const [questionResponse, setQuestionResponse] = useState({question: props.question.id, username: props.username});
  // Whether we've yet sent an answer. Used only to display confirmation messages
  const [sent, setSent] = useState(false);


  // Current method of sending responses using a POST request
  useEffect(() => {
    console.log(questionResponse);
    if (questionResponse.needsUpdate) {
      fetch(`/api/quizquestion/${props.question.id}/response`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(questionResponse)
      })
      .then(() =>
        {setQuestionResponse(prev=> ({...prev, needsUpdate: false}));
        setSent(true)}
      )
    }
  },[questionResponse]);



  // "questionResponse?" ensures that the initial response has been set up.
  return (questionResponse?
    <div className={styles.container}>
      <div className={styles.questionContainer}>
        {/* Main question text */}
        <p>{props.question.questionText}</p>
        {/* Images not currently supported on this app, can be skipped */}
        {props.question.image &&
          <img src={props.question.image} />
        }
      </div>
      {/* The various ways to respons to the question */}
      <div className={styles.answerContainer}>
        {/* If a text question */}
        {props.question.type == "text" &&
          <>
          {/* Essentially a text box with a send button.  */}
            <div className="input-group">
              <input
                className="form-control"
                type="text"
                value={questionResponse.answer}
                // We set 'sent' to false, because now the current value hasnt been sent.
                // We don't set 'needsUpdate' on every keypress because it would sent far too often.
                onChange={e => {setSent(false); let answer = e.target.value; setQuestionResponse({...questionResponse, answer})}}
                // Set needsUpdate if the enter key is pressed, meaning this answer will get sent by the useEffect
                onKeyUp={e => {if (e.key !== "Enter") return; setQuestionResponse({...questionResponse, needsUpdate: true}); e.preventDefault();}}
                placeholder="Enter your answer"
                // If revealAnswer is set, then this input is greyed out
                disabled={props.revealAnswer}
              />
              <div className="input-group-append">
                <button
                  type="submit"
                  className={`btn btn-primary`}
                  // Do the same as the above enter key event handler, if the send button is pressed
                  onClick={e=> setQuestionResponse({...questionResponse, needsUpdate: true})}
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
            </div>
            {/* If revealAnswer is set */}
            {props.revealAnswer ?
              <small class="form-text">
                {/* If, with whitespace trimmed and set to lowercase, this answer matches one of the correct answers sent back, show a tick, else show a cross */}
                {questionResponse.answer && props.question.answers.map(a => a.trim().toLowerCase()).includes(questionResponse.answer.trim().toLowerCase())?
                  <><FontAwesomeIcon icon={faCheck} color="green"/> Correct</>
                :
                  <><FontAwesomeIcon icon={faTimes} color="red"/> Incorrect.
                  {/* If wrong, show all the right answer options, comma separated (say 'answers' instead of 'answer' if there are multiple) */}
                  Correct answer{props.question.answers.length > 1 && 's'}: {props.question.answers.join(', ')}
                  </>
                }
              </small>
            :
            // Add this message below the textbox if the current answer has been submitted
            sent && questionResponse.answer && <small class="form-text">Answer submitted. You may still change your answer</small>
            }
            
          </>
        }
        {/* if a Multi-choice question */}
        {props.question.type == "mcq" &&
          <div className="mcqButtonsContainer">
            {/* Map each possible answer to a button */}
            {props.question.mcqOptions.map(o => {
              return (
                <button
                // If the currently selected answer, set 'btn-primary', else set 'btn-light'
                // This could instead be a reactstrap Button with primary/light switched on the 'color' attribute and no 'btn' class required
                  className={`btn btn-${o==questionResponse.answer? 'primary':'light'}`}
                  type="button"
                  // Disable these buttons if the answer is revealed
                  disabled={props.revealAnswer}
                  // Send the new answer on click
                  onClick={e => setQuestionResponse({...questionResponse, answer: o, needsUpdate: true})}
                >
                  {o} {props.revealAnswer && props.question?.answers?.includes(o) &&
                  // Add a tick if the answer is revealed and this is the right one.
                  <FontAwesomeIcon icon={faCheck} color="green"/>
                  }
                </button>
              )
            })}
          </div>
        }
      </div>
    </div>
    // Potentially never hit: If no questionresponse is set, show loading text.
  : <p>Loading...</p>);

}

export default QuestionPlayer;