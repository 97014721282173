import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router';

function GuestRoom(props) {

  const [username, setUsername] = useState([""]);
  const [joining, setJoining] = useState(false);
  let history = useHistory();
  let { roomId } = useParams();

  const handleInputChange = (e) => {
    e.preventDefault();
    setUsername(e.target.value);
  }

  const handleJoin = (e) => {
    const params = new URLSearchParams(window.location.search);
    const treeOnly = params.get('tree-only')
    e.preventDefault();
    setJoining(true);
    fetch('/api/user/guestkey/'+encodeURIComponent(username))
    .then(
      setTimeout(() => {
        history.push('/sharedtree/' + roomId + (treeOnly? "?tree-only" : ""))
      }, 500)
    )
  }

  return (
    <div className={"guest-room"}>
      <h1>Guest login</h1>
      {joining?
      <p>Please wait while we log you in...</p>
      :
      <div>
        <p>To join this room without an andeye account, enter a guest username and press Join</p>
        
        <form onSubmit={handleJoin}>
          <div className="form-group">
            <div className="input-group mt-2">
              <input
                autoFocus
                value={username}
                onChange={handleInputChange}
                type="text"
                className="form-control"
                placeholder="Enter a username"/>
              <div className="input-group-append">
                <button type="submit" className="btn btn-primary" disabled={username == ""}>
                  Join
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      }
      
    </div>
  )
}

export default GuestRoom; 