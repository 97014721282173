import React from 'react';
import cx from 'classnames';

export default function renderTreeSearchOption(props, option, snapshot, className) {

  if (!option.value) return null;

  let subtext = "";
  option.question.parent && !option.translated && (subtext += `Under: ${option.question.parent} `);

  return <button type="button" className={cx(className, 'py-1')} {...props}>
    {option.name} {
      option.question.type == "suggestion" &&
      <small class="search-option-votes">(&nbsp;+{option.question.upvotes.length || 0}&nbsp;/&nbsp;-{option.question.downvotes.length || 0}&nbsp;)</small>
    }
    {subtext && <><br/><small><em>{subtext}</em></small></>}
  </button>
}