import React, { useEffect, useRef, useState } from 'react';
import Player from '@vimeo/player';
function VimeoPlayer(props) {
  
  const playerRef = useRef(null)
  const player = useRef(null);
  const [muted, setMuted] = useState(true);
  
  useEffect(() => {
    const options = {...props.options};
    player.current = new Player(playerRef.current, options);
    player.current.on('playing', (data) => {
      player.current.getMuted().then(function(m) {
        setMuted(m);  
      }).catch(function(error) {
          console.log(error)
      });
    })
  }, [])

  return <div className="cocreation-vimeo">
    <div className="cocreation-vimeo-player" ref={playerRef}></div>

    {props.options?.url.includes("event") && muted && <button className="btn btn-light unmute" onClick={(e) => {player.current.setMuted(false); setMuted(false);}}>Unmute</button>}
  </div>

}

export default VimeoPlayer;